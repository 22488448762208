import React from 'react';

import { Stack, Typography } from '@mui/material';

import { adminDefaultSpacing } from '../../../defaults';
import Transactions from '../../parents/transactions/Transactions';

const AllTransactions = () => {
  return (
    <Stack width={'100%'} px={adminDefaultSpacing}>
      <Typography mb={3} variant="h5">
        All Transactions
      </Typography>
      <Transactions fromAdmin />
    </Stack>
  );
};

export default AllTransactions;
