import * as Yup from 'yup';

/**
 * Common validations
 */

export const regex = {
  name: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
  postalCode: /^[1-9][0-9]{5}$/,
  phone: /^[6-9]\d{9}$/,
  numericOnly: /\d$/,
  panNo: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  accountNumber: /^\d{9,18}$/,
  ifscCode: /^[A-Za-z]{4}[0][A-Z0-9a-z]{6}$/,
  email:
    /^([a-z][a-z0-9_]*|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/,
  multipleSpace: /\s\s/,
  businessName: /^(?=.*[A-Za-z])[A-Za-z0-9\s\W]+$/,
  street: /^(?=.*[A-Za-z])[A-Za-z0-9\s\W]+$/,
  message: /^(?=.*[A-Za-z])[A-Za-z0-9\s\W]+$/,
};

Yup.addMethod(Yup.number, 'integer', function (message) {
  return this.test('integer', message, function (value) {
    const { path, createError } = this;
    if (value !== undefined && value !== null) {
      return (
        /^\d+$/.test(value) ||
        createError({ path, message: message || 'Value must be an integer' })
      );
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'multipleSpaces', function (errorMsg) {
  return this.test('multipleSpaces', errorMsg, function (value) {
    const { path, createError } = this;
    if (regex.multipleSpace.test(value)) {
      return createError({
        path,
        message: errorMsg || 'More than one consecutive spaces are not allowed',
      });
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'multipleSpaces', function (errorMsg) {
  return this.test('multipleSpaces', errorMsg, function (value) {
    const { path, createError } = this;
    if (regex.multipleSpace.test(value)) {
      return createError({
        path,
        message: errorMsg || 'More than one consecutive spaces are not allowed',
      });
    }
    return true;
  });
});

const validationSchemas = {
  businessName: Yup.string()
    .trim()
    .nullable()
    .required('Business name is required')
    .matches(
      regex.businessName,
      'Business name should have at least one character'
    ),
  message: Yup.string()
    .trim()
    .required('Message is required')
    .max(1000, 'Message should be max of 1000 characters')
    .matches(regex.message, 'Message should have at least one character'),
  age: Yup.number()
    .nullable()
    .typeError('Please enter valid age')
    .moreThan(0, 'Age should be greater than 0')
    .max(14, 'Age must be less than or equal to 14')
    .integer('Enter a valid age')
    .required('Please enter valid age'),
  name: Yup.string()
    .trim()
    .nullable()
    .required('Name is required')
    .matches(regex.name, 'Please enter a valid name'),
  panNo: Yup.string()
    .required('PAN number is required')
    .matches(regex.panNo, 'Enter a valid PAN number'),
  accountNumber: Yup.string()
    .required('Account number is required')
    .matches(regex.accountNumber, 'Enter a valid account number'),
  beneficiaryName: Yup.string().trim().required('Beneficiary name is required'),
  ifscCode: Yup.string()
    .required('IFSC code is required')
    .matches(regex.ifscCode, 'Enter a valid IFSC code'),
  street1: Yup.string()
    .trim()
    .nullable()
    .required('Street 1 is required')
    .matches(regex.street, 'Street 1 should have at least one character'),
  street2: Yup.string()
    .trim()
    .nullable()
    .required('Street 2 is required')
    .matches(regex.street, 'Street 2 should have at least one character'),
  city: Yup.string()
    .trim()
    .nullable()
    .required('City is required')
    .matches(regex.name, 'Please enter a valid city'),
  state: Yup.string()
    .trim()
    .nullable()
    .required('State is required')
    .matches(regex.name, 'Please enter a valid state'),
  postalCode: Yup.string()
    .trim()
    .nullable()
    .required('Postal code is required')
    .matches(regex.postalCode, 'Please enter a valid postal code'),
  country: Yup.string()
    .trim()
    .nullable()
    .required('Country is required')
    .matches(regex.name, 'Please enter a valid country'),
  usernameEmail: Yup.string()
    .trim()
    .email('Enter valid Email')
    .required('Email is required')
    .min(3, 'Email is too short - should be 3 chars minimum')
    .max(50, 'Email should not exceed more than 50 characters'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,}).*$/,
      'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number and one special character'
    )
    .multipleSpaces(),
  phone: Yup.string()
    .nullable()
    .trim()
    .required('Phone is required')
    .matches(regex.phone, 'Please enter a valid phone number'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  contact: Yup.string()
    .required('Please enter your email or phone number')
    .test(
      'is-email-or-phone',
      'Please enter a valid email or 10-digit phone number',
      (value) => {
        if (!value) return true; // Allow empty field

        // Check for valid email format first
        if (Yup.string().email().isValidSync(value)) {
          return true;
        }

        // If not email, check for valid 10-digit phone number format
        return value.length === 10 && /^\d+$/.test(value);
      }
    ),
};

export default validationSchemas;
