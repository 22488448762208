import React, { useCallback, useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';

import { newEvent } from '../../utils/commonFunctions';

/**
 Types of Snackbar
1. success
2. error
3. warning
4. info
 */

export default function CustomizedSnackbar() {
  const [snackData, setSnackData] = useState([]);
  const handleSnackData = useCallback((data) => {
    setSnackData((prev) => [
      { open: true, type: 'success', ...data, id: Math.random() },
      ...prev,
    ]);
  }, []);

  useEffect(() => {
    newEvent.on('showToast', handleSnackData);
    return () => {
      newEvent.off('showToast', handleSnackData);
    };
  }, [handleSnackData]);

  // const handleClose = useCallback((_, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  // }, []);
  //clear the old snack if the snack stack is higher than 5
  useEffect(() => {
    if (snackData.length > 5) {
      setSnackData((prev) => {
        prev.length = 5;
        return prev;
      });
    }
  });
  const handleClick = useCallback((id) => {
    setSnackData((prevSnackData) =>
      prevSnackData.filter((item) => item.id !== id)
    );
  }, []);

  // useEffect(() => {
  //   let timer;
  //   if (snackData?.open) {
  //     timer = setTimeout(() => {
  //       handleClose();
  //     }, snackData?.duration || 3000);
  //   }
  //   return () => {
  //     if (timer) {
  //       clearTimeout(timer);
  //     }
  //   };
  // }, [snackData?.open, snackData.id, snackData?.duration, handleClose]);

  return (
    <Grid
      position="fixed"
      zIndex={10000}
      display="flex"
      flexDirection="column"
      top={10}
      right={15}
      left={15}
      flexGrow={1}
      // justifyContent="right"
      // spacing={2}
    >
      {snackData?.map((snack) => (
        <Box key={snack?.id} sx={{ position: 'relative' }} mb={7}>
          <Snackbar
            open={snack?.open}
            autoHideDuration={snack.type !== 'error' ? 3000 : 15000}
            onClose={(_, reason) => {
              if (reason === 'clickaway') {
                return;
              }
              setSnackData((prevSnackData) =>
                prevSnackData.filter((item) => item.id !== snack?.id)
              );
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              position: 'absolute',
            }}
          >
            <Alert
              variant="filled"
              key={snack?.id}
              onClose={() => handleClick(snack?.id)}
              sx={{
                color: '#fff',
              }}
              severity={snack?.type ?? 'error'}
            >
              {snack?.message || 'Something went wrong'}
            </Alert>
          </Snackbar>
        </Box>
      ))}
    </Grid>
  );
}
