import { combineReducers, configureStore } from '@reduxjs/toolkit';

import apiInstance from './apis/createApiInstance';
import errorPage from './apis/errorpage';
import user from './apis/userSlice';

const rootReducer = combineReducers({
  user,
  errorPage,
  [apiInstance.reducerPath]: apiInstance.reducer,
});
const reducer = (state, action) => {
  if (action.type === 'logout') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiInstance.middleware
    ),
});
