import { createApiInstance } from './createApiInstance';

import {
  queryParamsBuilder,
  updateCacheData,
} from '../../utils/commonFunctions';

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getMonthlySessions: build.query({
      providesTags: ['monthlySessions'],
      query({ id, query }) {
        return {
          url: `/api/sessions/monthly${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getCompletedSessions: build.query({
      providesTags: ['completedSessions'],
      query(query) {
        return {
          url: `/api/sessions/${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getSession: build.query({
      providesTags: ['getSession'],
      query({ id }) {
        console.log('idddd', id);
        return {
          url: `/api/sessions/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    cancelSession: build.mutation({
      invalidatesTags: ['monthlySessions'],
      query({ id }) {
        console.log('idddd', id);
        return {
          url: `/api/sessions/${id}`,
          method: 'DELETE',
        };
      },
    }),
    deleteSessions: build.mutation({
      invalidatesTags: ['monthlySlots', 'dailySlots', 'monthlySessions'],
      query({ ids }) {
        console.log('idddd', ids);
        return {
          url: '/api/sessions/deleteAll',
          method: 'DELETE',
          body: { ids },
        };
      },
    }),
    bookSession: build.mutation({
      query({ body }) {
        return {
          url: '/api/sessions',
          method: 'POST',
          body,
        };
      },
    }),
    updateSession: build.mutation({
      invalidatesTags: ['monthlySlots', 'dailySlots', 'monthlySessions'],
      query({ body, id }) {
        return {
          url: `/api/sessions/${id}/update-session`,
          method: 'PATCH',
          body,
        };
      },
    }),
    sendFeedbackEmail: build.mutation({
      invalidatesTags: ['getSession'],
      query({ id }) {
        return {
          url: `/api/sessions/${id}/send-feedback-email`,
          method: 'PATCH',
        };
      },
    }),
    savePdfToServer: build.mutation({
      invalidatesTags: ['completedSessions'],
      query({ id, body }) {
        return {
          url: `/api/sessions/${id}/notes`,
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            { state: getState(), method: 'getSession', dispatch, extendedApi },
            (draft) => {
              draft = data.data;
              return data.data;
            }
          );
        } catch (e) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    sendPdfToParent: build.mutation({
      invalidatesTags: ['completedSessions'],
      query({ id, body, query }) {
        return {
          url: `/api/sessions/${id}/send-notes${queryParamsBuilder(query)}`,
          method: 'PATCH',
          body,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    downloadSessionNote: build.query({
      query({ id }) {
        return {
          url: `/api/sessions/${id}/download-notes`,
          method: 'GET',
          responseHandler: (response) => {
            if (response.ok) return response.blob();
            else return response.json();
          },
        };
      },
    }),
    sessionFeedback: build.mutation({
      invalidatesTags: ['getTherapists', 'getTherapistReviews'],
      query({ body, sessionId }) {
        return {
          url: `/api/sessions/feedback/${sessionId}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    getSessionToken: build.query({
      query({ sessionId, query, meetingId }) {
        return {
          url: `/api/sessions/${sessionId}/get-room-token/${meetingId}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getUpcomingSession: build.query({
      query() {
        return {
          url: '/api/dashboards/upcoming-sessions',
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    downloadSessionReport: build.query({
      query() {
        return {
          url: '/api/dashboards/report/sessions',
          method: 'GET',
          responseHandler: (response) => {
            if (response.ok) return response.blob();
            else return response.json();
          },
        };
      },
    }),
  }),
});

export const {
  useGetMonthlySessionsQuery,
  useLazyGetSessionTokenQuery,
  useBookSessionMutation,
  useSessionFeedbackMutation,
  useGetCompletedSessionsQuery,
  useGetSessionQuery,
  useLazyGetSessionQuery,
  useSavePdfToServerMutation,
  useGetUpcomingSessionQuery,
  useSendPdfToParentMutation,
  useCancelSessionMutation,
  useUpdateSessionMutation,
  useSendFeedbackEmailMutation,
  useDeleteSessionsMutation,
  useLazyGetMonthlySessionsQuery,
  useLazyDownloadSessionNoteQuery,
  useLazyDownloadSessionReportQuery,
} = extendedApi;
