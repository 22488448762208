import React, {
  useCallback,
  useEffect,
  // useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { Icon } from '@iconify/react';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Popover,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import TherapistCard from './TherapistCard';

import ButtonWithLoading from '../../../components/common/ButtonWithLoading';
import { HookAutoComplete } from '../../../components/hook-form-fields/HookAutoComplete';
import HookSelectField from '../../../components/hook-form-fields/HookSelectField';
import { therapistListFilters } from '../../../defaults';
import { useGetLanguagesQuery } from '../../../store/apis/configApi';
import { useGetTherapistsQuery } from '../../../store/apis/user';
import { debounce } from '../../../utils/commonFunctions';

const Therapists = () => {
  const { data: languages, isLoading: isLanguagesLoading } =
    useGetLanguagesQuery();
  const [searchQuery, setSearchQuery] = useState('');
  const [randomizedKeys, setRandomizedKey] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [ageGroup, setAgeGroup] = React.useState([0, 14]);
  const [filterSearchValue, setFilterSearchValue] = useState('');

  const handleChange = (event, newValue) => {
    setAgeGroup(newValue);
  };

  const filter = useMemo(() => {
    const obj = {
      page: Number(searchParams.get('page') || 1),
      search: searchParams.get('search'),
      sort:
        searchParams.get('sort') && searchParams.get('sort') !== 'Sponsored'
          ? `session.${searchParams.get('sort')}`
          : '',
      ageGroup: searchParams.get('ageGroup') || '',
      languages: searchParams.get('languages') || '',
      filter: '',
    };

    if (obj.languages) {
      obj.filter = `${obj.filter ? `${obj.filter},` : ''}languages:${obj.languages}`;
    }
    if (obj.ageGroup) {
      obj.filter = `${obj.filter ? `${obj.filter},` : ''}ageGroup:${obj.ageGroup}`;
    }
    return {
      search: filterSearchValue,
      sort: obj.sort,
      page: obj.page,
      filter: obj.filter || '',
      limit: 12,
    };
  }, [filterSearchValue, searchParams]);

  const customHeader = useMemo(() => {
    if (
      filter.page &&
      filter.page !== 1 &&
      !filter.search &&
      !filter.sort &&
      !filter.filter &&
      randomizedKeys
    ) {
      return { 'X-Sorting-value': randomizedKeys };
    } else {
      return {};
    }
  }, [filter, randomizedKeys]);

  const {
    data: therapists,
    isFetching,
    isLoading,
  } = useGetTherapistsQuery(
    {
      filter,
      customHeader,
    },
    { refetchOnMountOrArgChange: true }
  );

  const setFilteredValue = useCallback((searchQuery) => {
    setFilterSearchValue(searchQuery);
  }, []);

  useEffect(() => {
    const randomKey = sessionStorage.getItem('randomizedKey');
    if (therapists?.randomizedKey && (!filter.page || filter.page === 1))
      sessionStorage.setItem('randomizedKey', therapists?.randomizedKey);
    if (randomKey && filter.page > 1) {
      setRandomizedKey(randomKey);
    } else {
      setRandomizedKey(therapists?.randomizedKey);
    }
    if (filter.page > 1 && !randomKey) {
      setSearchParams((prev) => {
        prev.set('page', 1);
        return prev;
      });
    }
  }, [filter.page, setSearchParams, therapists]);

  useEffect(() => {
    debounce(() => {
      setFilteredValue(searchQuery);
      if (filter.page > 1 && searchQuery)
        setSearchParams((prev) => {
          prev.set('page', 1);
          return prev;
        });
    }, 1000);
  }, [searchQuery, setFilteredValue]);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      search: '',
      sort: 'Sponsored',
      languages: [],
    },
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async (data) => {
      setSearchParams({
        search: data?.search,
        sort: data?.sort,
        ageGroup: `${ageGroup[0]}-${ageGroup[1]}`,
        languages: `${(data.languages || [])
          .map((lang) => lang._id)
          .join('-')}`,
      });
      handleClose();
    },
    [ageGroup, setSearchParams]
  );
  const handleChangePage = (event, newPage) => {
    if (parseInt(searchParams.get('page') || 1) !== parseInt(newPage))
      window.scrollTo({ top: 500, behavior: 'smooth' });
    setSearchParams((prev) => {
      prev.set('page', newPage);
      return prev;
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Stack mt={{ md: 7 }} gap={4} width={'100%'}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        width={'100%'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={{ sm: 'center' }}
      >
        <Typography textAlign={'left'} variant="h4">
          Therapists
        </Typography>
        <Stack
          justifyContent={{ md: 'flex-end', xs: 'center' }}
          gap={2}
          direction={{ xs: 'row', sm: 'row' }}
          alignItems={'center'}
        >
          <TextField
            name="search"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            value={searchQuery}
            InputProps={{
              startAdornment: (
                <Icon
                  style={{ marginRight: '8px' }}
                  fontSize={24}
                  icon={'carbon:search'}
                />
              ),
              sx: {
                'backgroundColor': (theme) =>
                  theme.palette.background.lightBlue,
                'borderRadius': '32px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
              },
            }}
            placeholder="Search"
          />
          <IconButton onClick={handleClick}>
            <Icon icon={'mage:filter'} />
          </IconButton>
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ boxShadow: '0px 60px -15px -40px #07404B26 !important' }}
      >
        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
          })}
          style={{ height: '100%' }}
        >
          <Stack
            p={3}
            gap={2}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Stack gap={1} width={{ xs: '100%' }}>
              <Typography variant="body1">Sort By:</Typography>
              <HookSelectField
                name="sort"
                control={control}
                valueField="value"
                labelField="label"
                options={therapistListFilters}
              />
            </Stack>

            <Stack gap={1} width={{ xs: '100%' }}>
              <Typography variant="body1">Language:</Typography>
              <HookAutoComplete
                isLoading={isLanguagesLoading}
                disabled={isLoading}
                name={'languages'}
                label=""
                control={control}
                fullWidth={true}
                multiple={true}
                options={languages || []}
              />
            </Stack>

            <Stack
              // minWidth={{ xs: '90%', sm: 'auto' }}
              gap={2.5}
            >
              <Typography variant="body1">Age Group</Typography>
              <Slider
                name="ageGroup"
                control={control}
                valueLabelDisplay="on"
                value={ageGroup}
                min={0}
                max={14}
                onChange={handleChange}
                sx={{
                  'marginRight': '10px',
                  'minWidth': {
                    xs: '150px',
                    sm: '100px',
                    md: '200px',
                    lg: '250px',
                  },
                  '&.MuiSlider-valueLabel': {
                    backgroundColor: 'transparent !important',
                  },
                }}
              />
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'flex-end'} gap={2}>
              <ButtonWithLoading
                variant={'text'}
                customStyle={{ px: 4, mt: 3 }}
                fullWidth={false}
                title={'Clear Filter'}
                onClick={() => {
                  reset();
                  setAgeGroup([0, 14]);
                  setSearchParams({});
                  handleClose();
                }}
              />
              <ButtonWithLoading
                type={'submit'}
                size={'small'}
                customStyle={{ px: 4, mt: 3 }}
                fullWidth={false}
                isLoading={isLoading}
                title={'Apply'}
              />
            </Stack>
          </Stack>
        </form>
      </Popover>
      <Grid spacing={3} mb={4} container>
        {isFetching || isLoading ? (
          <Box
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            width={'100%'}
            py={10}
          >
            <CircularProgress />
          </Box>
        ) : therapists?.data?.length ? (
          therapists?.data.map((d) => (
            <Grid xs={12} sm={6} md={6} lg={4} item key={d._id}>
              <TherapistCard data={d} />
            </Grid>
          ))
        ) : (
          <Box
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            width={'100%'}
            py={10}
          >
            <Typography>No therapists found!</Typography>
          </Box>
        )}
        {/* {therapists?.data && ( */}

        {/* )} */}
      </Grid>
      {!isFetching && !isLoading && (
        <Stack justifyContent={'center'} alignItems={'center'}>
          <Pagination
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                color: (theme) => theme.palette.text.primary,
              },
            }}
            color="primary"
            shape="rounded"
            count={therapists?.metadata?.totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default Therapists;
