import React, { useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ButtonWithLoading from '../../components/common/ButtonWithLoading';
import ForgotPasswordWrapper from '../../components/common/ForgotPasswordWrapper';
import { useAuth } from '../../context/AuthProvider';
import {
  useLazyResendVerificationQuery,
  useVerifyTokenQuery,
} from '../../store/apis/auth';
import { showToast } from '../../utils/commonFunctions';

const VerifyToken = () => {
  const [queries] = useSearchParams();
  const token = queries.get('token');
  const { data, isLoading, error } = useVerifyTokenQuery(
    { token },
    { skip: !token }
  );
  const navigate = useNavigate();
  const auth = useAuth();
  const [resendVerificationLink, { isLoading: isResend }] =
    useLazyResendVerificationQuery();
  useEffect(() => {
    if (!isLoading && data?.status === 'SUCCESS' && data.data) {
      navigate('/sign-in');
      showToast({
        message: data?.data?.message || 'Email verified successfully',
        type: 'success',
      });
    } else {
      const reason = data?.data?.reason;
      if (reason === 'invalid') {
        navigate('/sign-in');
      }
    }
  }, [auth, data?.data, data?.status, isLoading, navigate]);

  return isLoading ? (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    error && (
      <ForgotPasswordWrapper
        iconName="mdi:lock-reset"
        title={error?.data?.data?.status}
        description={error?.data?.data?.message}
      >
        {error?.data?.data?.status !== 404 && (
          <ButtonWithLoading
            onClick={async () => {
              const res = await resendVerificationLink({
                token: token,
              });
              if (res?.data?.status === 'SUCCESS') {
                showToast({
                  type: 'success',
                  message: res?.data?.message,
                });
              }
            }}
            isLoading={isResend}
            title="Resend Verification Email"
          />
        )}
      </ForgotPasswordWrapper>
    )
  );
};

export default VerifyToken;
