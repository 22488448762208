import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import SessionFeedBackModal from '../sessions/components/SessionFeedBackModal';

const SendFeedback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return (
    <div>
      <SessionFeedBackModal
        open={true}
        handleCancel={() => {
          navigate({
            pathname: '/',
            search: searchParams.toString(),
          });
        }}
      />
    </div>
  );
};

export default SendFeedback;
