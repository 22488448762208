import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ButtonWithLoading from '../../../components/common/ButtonWithLoading';
import { HookTextField } from '../../../components/hook-form-fields/HookTextField';
import { useVerifyTherapistMutation } from '../../../store/apis/user';
import { showToast } from '../../../utils/commonFunctions';

const validationSchema = yup.object({
  comment: yup.string().required().trim(),
});
const RejectionForm = ({ setRejectForm, step, therapistId }) => {
  const [verify, { isLoading }] = useVerifyTherapistMutation();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      comment: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    const res = await verify({
      body: {
        step,
        status: 'REJECTED',
        comment: data?.comment,
      },
      id: therapistId,
    });

    if (res?.error) {
      showToast({
        type: 'error',
        message: res.error.data.message,
      });
      return;
    }
    showToast({
      type: 'success',
      message: res.data.message,
    });
  };
  return (
    <form
      style={{ height: '100%' }}
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <Typography mb={2} variant="h6">
        Reason for Rejection
      </Typography>
      <HookTextField
        multiline
        rows={3}
        required={true}
        name={'comment'}
        label="Enter Reason for Rejection"
        control={control}
      />
      <Stack flexDirection={'row'} justifyContent={'flex-end'} gap={2}>
        <ButtonWithLoading
          variant={'text'}
          customStyle={{ px: 4, mt: 3 }}
          fullWidth={false}
          title={'Cancel'}
          onClick={() => {
            reset();
            setRejectForm(false);
          }}
        />
        <ButtonWithLoading
          type={'submit'}
          customStyle={{ px: 4, mt: 3 }}
          fullWidth={false}
          isLoading={isLoading}
          title={'Submit'}
        />
      </Stack>
    </form>
  );
};

RejectionForm.propTypes = {
  setRejectForm: PropTypes.func,
  step: PropTypes.any,
  therapistId: PropTypes.any,
};

export default RejectionForm;
