import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import { AuthRedirect } from '../context/AuthProvider';

const getRouteWrapper = (component, authRoute = true) => {
  return (
    <AuthRedirect authenticatedRoute={authRoute}>
      <React.Suspense
        fallback={
          <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        }
      >
        {component}
      </React.Suspense>
    </AuthRedirect>
  );
};

export default getRouteWrapper;
