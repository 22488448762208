import React from 'react';

import { Box, Typography } from '@mui/material';

function Offline() {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
          gap: 3,
          borderRadius: '32px',
          boxShadow: '0px 0px 16px 0px gray',
        }}
      >
        <Box>
          <svg
            width="186"
            height="186"
            viewBox="0 0 121 121"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M108.383 71.7031C114.83 44.9978 98.4084 18.122 71.7031 11.6744C44.9978 5.22681 18.1221 21.6489 11.6744 48.3542C5.22683 75.0595 21.6489 101.935 48.3542 108.383C75.0595 114.83 101.935 98.4084 108.383 71.7031Z"
              fill="#F5F5F5"
            />
            <path
              d="M62.2629 81.9628C62.2529 84.2328 60.4039 86.0648 58.1339 86.0538C55.8639 86.0428 54.0319 84.1948 54.0429 81.9248C54.0529 79.6548 55.9019 77.8228 58.1719 77.8338C60.4429 77.8438 62.2739 79.6928 62.2629 81.9628Z"
              fill="#07404B"
            />
            <path
              d="M49.9389 76.6798C54.4529 71.9408 61.7889 71.7958 66.4179 76.4628C68.5159 78.5778 71.8009 75.3248 69.7019 73.2088C63.3499 66.8068 52.9229 66.8448 46.6849 73.3958C44.6339 75.5488 47.8819 78.8398 49.9389 76.6798Z"
              fill="#07404B"
            />
            <path
              d="M35.9779 57.5478C42.2279 52.1448 49.6889 48.7438 58.0439 48.7228C66.3249 48.7018 74.4909 51.6418 80.5559 57.3378C82.7229 59.3728 86.0149 56.1258 83.8399 54.0838C76.8929 47.5588 67.5749 44.0758 58.0649 44.0998C48.4509 44.1238 39.9059 48.0538 32.7239 54.2628C30.4689 56.2128 33.7349 59.4868 35.9779 57.5478Z"
              fill="#07404B"
            />
            <path
              d="M41.4879 68.1498C45.6779 63.6198 51.5499 60.7828 57.7559 60.6418C64.1309 60.4968 70.2859 63.1218 74.7179 67.6758C76.7969 69.8108 80.0799 66.5568 78.0019 64.4218C72.7569 59.0338 65.3259 55.8468 57.7779 56.0188C50.3959 56.1878 43.2419 59.4528 38.2349 64.8658C36.2159 67.0468 39.4619 70.3398 41.4879 68.1498Z"
              fill="#07404B"
            />
            <path
              d="M96.9663 48.8587C98.1792 41.9987 93.6013 35.4544 86.7414 34.2416C79.8815 33.0287 73.3372 37.6065 72.1243 44.4665C70.9114 51.3264 75.4893 57.8707 82.3492 59.0836C89.2091 60.2964 95.7534 55.7186 96.9663 48.8587Z"
              fill="#F44336"
            />
            <path
              d="M89.7599 43.1363L87.9723 41.3487L79.963 49.358L81.7506 51.1456L89.7599 43.1363Z"
              fill="white"
            />
            <path
              d="M81.7477 41.353L79.9601 43.1405L87.9694 51.1499L89.757 49.3623L81.7477 41.353Z"
              fill="white"
            />
          </svg>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h3">Connection Lost !</Typography>
          <Typography variant="subtitle1">
            There seems to be problem with your internet connection.
          </Typography>
          <Typography variant="subtitle1">
            Please check your connection or try again!
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Offline;
