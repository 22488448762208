import React, { useContext, createContext, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Navigate,
  matchRoutes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import useGetUserData from '../hooks/useGetUserData';
import createApiInstance from '../store/apis/createApiInstance';
import {
  deleteAllCookies,
  getCookie,
  setCookie,
} from '../utils/commonFunctions';
const AuthContext = createContext();

const oldRefToken = getCookie('refresh')
  ? JSON.parse(getCookie('refresh'))
  : false;
const AuthProvider = ({ children }) => {
  const token = getCookie('token') ? JSON.parse(getCookie('token')) : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth, setAuth] = useState({
    refreshToken: oldRefToken,
    accessToken: token,
  });
  const removeAuth = () => {
    // dispatch({ type: 'logout' });
    deleteAllCookies();
    dispatch(createApiInstance.util.resetApiState());
    setAuth(undefined);
    navigate('/sign-in');
  };
  const addAuth = ({ accessToken, refreshToken }) => {
    setCookie('token', accessToken);
    setCookie('refresh', refreshToken);
    setAuth({ accessToken, refreshToken });
  };

  const value = {
    authenticated: auth?.accessToken || auth?.refreshToken,
    setAuth: addAuth,
    removeAuth,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function AuthRedirect({ children, authenticatedRoute = true }) {
  let auth = useAuth();
  let location = useLocation();
  const setToken = () => {
    auth.setAuth({
      accessToken: searchParams.get('token'),
      refreshToken: searchParams.get('refresh'),
    });
  };
  const [searchParams] = useSearchParams();
  const { data: userData, isLoading, role } = useGetUserData();
  const therapistPublicRoutes = [
    { path: '/' },
    { path: '/about-us' },
    { path: '/contact-us' },
    { path: '/terms-conditions' },
    { path: '/privacy-policy' },
    { path: '/refund-policy' },
    { path: '/therapist/:id' },
    { path: '/verify-token' },
  ];

  const allowedPublicTherapist = matchRoutes(therapistPublicRoutes, location);

  const parentPublic = [{ path: '/' }, { path: '/therapist/:id' }];

  const allowedPublic = matchRoutes(parentPublic, location);

  // const isProfile = location.pathname === '/therapists/profile';
  if (isLoading) {
    return (
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (
    searchParams.get('error') &&
    ['/therapists/onboarding', '/parents/onboarding', '/'].includes(
      location.pathname
    )
  ) {
    return (
      <Navigate
        to={`/sign-up?error=${searchParams.get('error')}`}
        state={{ from: location }}
      />
    );
  }

  if (
    searchParams.get('token') &&
    ['/therapists/onboarding', '/parents/onboarding', '/'].includes(
      location.pathname
    )
  ) {
    setToken();
    return <Navigate to={location.pathname} state={{ from: location }} />;
  }

  if (auth?.authenticated) {
    const previousPath = JSON.parse(sessionStorage.getItem('previousPath'));
    if (previousPath && previousPath.pathname !== location.pathname) {
      sessionStorage.removeItem('previousPath');
      return <Navigate to={previousPath} state={{ from: location }} />;
    }
    const isOnboardingPending = ['IN_PROGRESS', 'NOT_STARTED'].includes(
      userData?.onboarding?.status
    );
    const inAdminReview =
      role === 'therapists' &&
      ['REJECTED', 'IN_PROGRESS', undefined, 'PENDING'].includes(
        userData?.onboarding?.adminStatus
      );

    if (!isOnboardingPending && location.pathname.includes('/onboarding')) {
      if (role === 'therapists') {
        return (
          <Navigate to="/therapists/dashboard" state={{ from: location }} />
        );
      }

      return <Navigate to="/" state={{ from: location }} />;
    }
    if (!authenticatedRoute || isOnboardingPending || inAdminReview) {
      if (allowedPublicTherapist) {
        return children;
      }
      if (isOnboardingPending) {
        if (role === 'therapists') {
          if (location.pathname !== '/therapists/onboarding') {
            return (
              <Navigate
                to="/therapists/onboarding"
                state={{ from: location }}
              />
            );
          }
          return children;
        }
        if (role === 'parents') {
          if (location.pathname !== '/parents/onboarding') {
            return (
              <Navigate to="/parents/onboarding" state={{ from: location }} />
            );
          }
          return children;
        }
      } else if (inAdminReview) {
        if (location.pathname !== '/therapists/profile') {
          return (
            <Navigate to="/therapists/profile" state={{ from: location }} />
          );
        }
      } else if (!allowedPublic) {
        if (role === 'therapists') {
          return (
            <Navigate to="/therapists/dashboard" state={{ from: location }} />
          );
        }
        if (role === 'super_admin') {
          return <Navigate to="/admin/dashboard" state={{ from: location }} />;
        }
        return <Navigate to="/" state={{ from: location }} />;
      }
    }
  } else if (authenticatedRoute) {
    sessionStorage.setItem('previousPath', JSON.stringify(location));
    return <Navigate to="/sign-in" state={{ from: location }} />;
  }

  return children;
}

AuthRedirect.propTypes = {
  authenticatedRoute: PropTypes.bool,
  children: PropTypes.any,
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
