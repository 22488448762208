import React, { useCallback } from 'react';

import { Alert, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import AccordionDetailWrapper from '../../../components/common/AccordionDetailWrapper';
import BasicBreadcrumbs from '../../../components/common/BreadCrum';
import PageLoader from '../../../components/common/PageLoader';
import { useGetUserInfoQuery } from '../../../store/apis/user';
import DomainDetailForm from '../../therapist/onboarding/DomainDetailForm';
import PrimaryDetailForm from '../../therapist/onboarding/PrimaryDetailForm';
// import SlotConfig from '../../therapist/onboarding/SlotConfig';

const TherapistDetail = () => {
  const { id } = useParams();
  const {
    data: therapistData,
    isLoading,
    isFetching,
  } = useGetUserInfoQuery({ id, role: 'therapists' }, { skip: !id });
  console.log('therapists ===>', therapistData);
  const getStatus = useCallback(
    (step) => {
      if (step === 2) {
        return therapistData?.status === 'UNDER_REVERIFICATION' ||
          therapistData?.status === 'REVERIFICATION_FAILED'
          ? therapistData?.status
          : therapistData?.onboarding?.verificationLogs?.find(
              (log) => log.step === step
            )?.status;
      }
      return therapistData?.onboarding?.verificationLogs?.find(
        (log) => log.step === step
      )?.status;
    },
    [therapistData]
  );

  return isLoading || isFetching ? (
    <PageLoader />
  ) : (
    <Stack px={6} gap={3} width={'100%'}>
      <BasicBreadcrumbs
        currentPage="Therapist Detail"
        links={[{ title: 'Therapists', path: '/admin/therapists' }]}
      />
      {therapistData?.status === 'DELETED' && (
        <Alert
          severity="error"
          color="error"
          sx={{
            backgroundColor: (theme) => theme.palette.error.light,
          }}
        >
          <Typography sx={{ color: (theme) => theme.palette.error.dark }}>
            This account has been deleted by the therapist.
          </Typography>
        </Alert>
      )}
      {['NOT_STARTED', 'IN_PROGRESS'].includes(
        therapistData?.onboarding?.status
      ) && (
        <Alert
          severity="warning"
          color="warning"
          sx={{
            backgroundColor: (theme) => theme.palette.warning.light,
          }}
        >
          <Typography>
            Therapist&apos;s onboarding process is still pending. You cannot
            reject or approve until it&apos;s completed.
          </Typography>
        </Alert>
      )}
      <AccordionDetailWrapper
        getStatus={() => getStatus(1)}
        title={'Personal Details'}
        role={'admin'}
      >
        <PrimaryDetailForm
          therapistData={therapistData}
          getStatus={() => getStatus(1)}
          fromAdmin={true}
        />
      </AccordionDetailWrapper>

      <AccordionDetailWrapper
        getStatus={() => getStatus(2)}
        title={'Professional Details'}
        role={'admin'}
      >
        <DomainDetailForm
          therapistData={therapistData}
          getStatus={() => getStatus(2)}
          fromAdmin={true}
        />
      </AccordionDetailWrapper>

      {/* <AccordionDetailWrapper
        getStatus={() => getStatus(3)}
        title={'Session Details'}
      >
        <SlotConfig therapistData={therapistData} fromAdmin={true} />
      </AccordionDetailWrapper> */}

      {/* <AccordionDetailWrapper title={'Business Details'}>
        <BusinessDetails therapistData={therapistData} fromAdmin={true} />
      </AccordionDetailWrapper> */}
    </Stack>
  );
};

export default TherapistDetail;
