import React from 'react';

import { Navigate } from 'react-router-dom';

import getRouteWrapper from './getRouteWrapper';

import AuthLayout from '../layouts/AuthLayout';
import ContactUs from '../pages/admin/inquiries/Inquiries';
import ParentDetail from '../pages/admin/parent/components/ParentDetail';
import Parents from '../pages/admin/parent/Parents';
import TherapistDetail from '../pages/admin/therapist/TherapistDetail';
import Therapists from '../pages/admin/therapist/Therapists';
import TherapistTransaction from '../pages/admin/therapist/TherapistTransaction';
import AllTransactions from '../pages/admin/transactions/AllTransactions';

const NotFound = React.lazy(() => import('../pages/not-found/NotFound'));
const AdminDashboard = React.lazy(
  () => import('../pages/admin/admin-dashboard/AdminDashboard')
);

const adminRoutes = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: <Navigate to="admin/dashboard" />,
      },
      {
        path: '/admin',
        element: getRouteWrapper(<AuthLayout />),
        children: [
          {
            index: true,
            element: <Navigate to="dashboard" />,
          },
          {
            path: 'dashboard',
            element: getRouteWrapper(<AdminDashboard />),
          },
          {
            path: 'therapists',
            element: getRouteWrapper(<Therapists />),
          },
          {
            path: 'transactions',
            element: getRouteWrapper(<AllTransactions />),
          },
          {
            path: 'inquiries',
            element: getRouteWrapper(<ContactUs />),
          },
          {
            path: 'parents',
            children: [
              {
                path: '',
                element: getRouteWrapper(<Parents />),
              },
              {
                path: ':id',
                element: getRouteWrapper(<ParentDetail />),
              },
            ],
          },
          {
            path: 'therapists/:id',
            element: getRouteWrapper(<TherapistDetail />),
          },
          {
            path: 'therapists/:id/transactions',
            element: getRouteWrapper(<TherapistTransaction />),
          },
          {
            path: '*',
            element: getRouteWrapper(<NotFound />),
          },
        ],
      },
      {
        path: '*',
        element: getRouteWrapper(<NotFound />, false),
      },
    ],
  },
];

export default adminRoutes;
