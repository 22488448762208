import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const RHFCheckbox = ({
  name,
  disabled = false,
  label,
  control,
  onChange,
  checkedValue,
  ...other
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={checkedValue ?? field?.value}
              onChange={(value) => {
                field?.onChange(value);
                if (onChange) {
                  onChange(value);
                }
              }}
            />
          }
          label={label}
          {...other}
        />
      )}
    />
  );
};

RHFCheckbox.propTypes = {
  control: PropTypes.any,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  checkedValue: PropTypes.bool,
};
const RHFMultiCheckbox = ({
  name,
  options,
  isRow,
  readOnly = false,
  control,
  disabled = false,
  ...other
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) => {
          if (field?.value) {
            return field?.value?.includes(option)
              ? field?.value?.filter((value) => value !== option)
              : [...field.value, option];
          } else return [option];
        };

        return (
          <FormGroup row={isRow}>
            {options.map((option, index) => (
              <FormControlLabel
                key={option + index}
                control={
                  <Checkbox
                    disabled={readOnly || disabled}
                    checked={field?.value?.includes(option) || false}
                    onChange={() => field?.onChange(onSelected(option))}
                  />
                }
                label={option}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
};

RHFMultiCheckbox.propTypes = {
  control: PropTypes.any,
  isRow: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

export { RHFCheckbox, RHFMultiCheckbox };
