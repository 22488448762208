import React, { useState } from 'react';

import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Rating, Stack, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

// import thankYouEmoji from '../../../../assets/svg/thank-you.svg';
import ButtonWithLoading from '../../../../components/common/ButtonWithLoading';
import ModalContainer from '../../../../components/common/ModalContainer';
import TextEndAdornment from '../../../../components/common/TextEndAdornment';
import { HookTextField } from '../../../../components/hook-form-fields/HookTextField';
import {
  useGetSessionQuery,
  useSendFeedbackEmailMutation,
  useSessionFeedbackMutation,
} from '../../../../store/apis/session';
import { showToast } from '../../../../utils/commonFunctions';
const customIcons = {
  1: {
    label: 'Terrible',
  },
  2: {
    label: 'Bad',
  },
  3: {
    label: 'Okay',
  },
  4: {
    label: 'Good',
  },
  5: {
    label: 'Amazing',
  },
};

const validationSchema = yup.object({
  feedback: yup
    .string()
    .max(200, 'Feedback message must be 200 characters or less'),
  rating: yup.number().required('Rating is required'),
});

const SessionFeedBackModal = ({ open, handleCancel }) => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const ltThenMD = useMediaQuery(theme.breakpoints.down('md'));
  console.log('12121212121', ltThenMD);
  function IconContainer(props) {
    // eslint-disable-next-line react/prop-types
    const { value, ...other } = props;
    return (
      <Stack
        {...other}
        flex={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Icon icon={'ic:round-star'} fontSize={ltThenMD ? 40 : 71} />
        <Typography variant={ltThenMD ? 'body2' : 'body1'}>
          {customIcons[value].label}
        </Typography>
      </Stack>
    );
  }
  const { data: selectedSession } = useGetSessionQuery(
    { id: searchParams.get('sessionId') },
    { skip: !searchParams.get('sessionId') }
  );
  const [thankYouSection, setThankYouSection] = useState(false);
  const [submitFeedBack, { isLoading }] = useSessionFeedbackMutation();
  const [sendFeedback] = useSendFeedbackEmailMutation();
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      feedback: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    const res = await submitFeedBack({
      body: data,
      sessionId: searchParams.get('sessionId'),
    });
    if (res?.data?.status === 'SUCCESS') {
      showToast({
        type: 'success',
        message: 'Feedback submitted successfully',
      });
      setThankYouSection(true);
      reset();
    } else {
      showToast({
        type: 'error',
        message: res.error.data.message,
      });
    }
    console.log('data', res);
  };
  return (
    <ModalContainer
      maxWidth="sm"
      handleCancel={() => {
        setThankYouSection(false);
        handleCancel();
        reset();
        if (!thankYouSection && !selectedSession?.isFeedbackEmailSent) {
          sendFeedback({ id: searchParams.get('sessionId') });
        }
      }}
      open={open}
    >
      {thankYouSection ? (
        <Stack gap={4} justifyContent={'center'} alignItems={'center'}>
          {/* <img
            height={100}
            width={100}
            src={thankYouEmoji}
            alt="thankYouEmoji"
          /> */}
          <Icon icon="emojione:folded-hands-medium-skin-tone" fontSize={80} />
          <Stack gap={1} justifyContent={'center'} alignItems={'center'}>
            <Typography variant="h5">Thank you for your feedback!</Typography>
            <Typography textAlign={'center'} variant="h6">
              We do our best to take care of our customers
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <>
          <Stack gap={2}>
            <Typography variant="h6">Give Feedback</Typography>
            <Typography variant="subtitle1">
              How satisfied are you with our therapist?
            </Typography>
          </Stack>
          <form
            onSubmit={handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            <Stack>
              <Rating
                sx={{
                  mt: 3,
                  width: '100%',
                  justifyContent: 'space-between',
                }}
                name="rating"
                defaultValue={0}
                onChange={(e) => {
                  if (e.type === 'change') {
                    setValue('rating', e.target.value);
                  } else {
                    setValue('rating', null);
                  }
                  trigger('rating');
                }}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly
              />
              <Typography variant="caption" color={'error.main'}>
                {errors?.rating?.message}
              </Typography>
            </Stack>
            <Typography mt={3} mb={1} variant="subtitle2">
              What are the main reason for your rating?
            </Typography>
            <HookTextField
              multiline
              rows={6}
              name={'feedback'}
              placeholder="Tell us more.."
              control={control}
              InputProps={{
                endAdornment: (
                  <TextEndAdornment
                    editMode={true}
                    showCount={
                      watch('feedback').length
                        ? `${watch('feedback').length}/200`
                        : ''
                    }
                  />
                ),
              }}
              // onChange={(e) => setValue('feedback', e.target.value)}
            />
            <Stack>
              <ButtonWithLoading
                type={'submit'}
                customStyle={{ px: 4, mt: 3, alignSelf: 'end' }}
                fullWidth={false}
                isLoading={isLoading}
                title={'Submit'}
              />
            </Stack>
          </form>
        </>
      )}
    </ModalContainer>
  );
};

SessionFeedBackModal.propTypes = {
  eventData: PropTypes.any,
  handleCancel: PropTypes.any,
  open: PropTypes.any,
};

export default SessionFeedBackModal;
