import React, { useCallback, useState } from 'react';

import { Icon } from '@iconify/react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import fileSvg from '../../assets/svg/file.svg';
import imageSvg from '../../assets/svg/image.svg';
import odtSvg from '../../assets/svg/odt.svg';
import pdfSvg from '../../assets/svg/pdf.svg';
import txtSvg from '../../assets/svg/txt.svg';
import docxSvg from '../../assets/svg/word.svg';
import config from '../../config';
import { getUserId, showToast } from '../../utils/commonFunctions';
const supportedFiles = ['pdf', 'docx', 'doc', 'odt', 'ott', 'txt'];
const imageExtensions = ['jpg', 'png', 'jpeg', 'webp', 'gif', 'svg'];
const docxExtensions = ['docx', 'doc'];
const odtExtensions = ['odt', 'ott'];
export const DisplayDocuments = ({
  documents = [],
  onDeleteDocument,
  showPreview = true,
  disabled = false,
  editMode = true,
}) => {
  const { id } = getUserId();

  return (
    <Stack gap={1} width={'100%'}>
      {Array.from(documents)?.length > 0 &&
        Array.from(documents)?.map((doc) => {
          const docName = doc?.name || doc?.url || doc;
          const fileExtension = docName?.split('.')?.pop()?.toLowerCase();
          const fileIcon = imageExtensions.includes(fileExtension)
            ? imageSvg
            : docxExtensions.includes(fileExtension)
              ? docxSvg
              : odtExtensions.includes(fileExtension)
                ? odtSvg
                : fileExtension === 'pdf'
                  ? pdfSvg
                  : fileExtension === 'txt'
                    ? txtSvg
                    : fileSvg;
          return (
            <Stack
              key={doc?.name || doc?._id || doc?.url || doc}
              width={'100%'}
              justifyContent={'space-between'}
              direction="row"
              alignItems="center"
              gap={1}
            >
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Stack
                  sx={{
                    p: 1,
                    borderRadius: '50%',
                    border: (theme) =>
                      `1px solid ${theme.palette.text.disabled}`,
                  }}
                >
                  <img src={fileIcon} alt="pdf" height={24} width={24} />
                </Stack>
                <Typography
                  sx={{
                    //   maxWidth: '150px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    wordBreak: 'break-all',
                  }}
                >
                  {doc?.name || doc?.url || doc}
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                {showPreview && (
                  <IconButton
                    onClick={() =>
                      window.open(
                        !(doc?.url || doc)?.includes('documents/')
                          ? doc
                          : `${config.baseUrl}api/users/document?url=${doc?.url || doc}&token=${btoa(id)}`
                      )
                    }
                  >
                    <Icon icon={'akar-icons:eye'} />
                  </IconButton>
                )}
                {onDeleteDocument && editMode && (
                  <IconButton
                    disabled={disabled}
                    sx={{ color: (theme) => theme.palette.text.error }}
                    onClick={() => onDeleteDocument(doc)}
                  >
                    <Icon icon={'bitcoin-icons:cross-filled'} />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          );
        })}
    </Stack>
  );
};

DisplayDocuments.propTypes = {
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  onDeleteDocument: PropTypes.func,
  showPreview: PropTypes.bool,
  disabled: PropTypes.bool,
};

const UploadDocuments = ({
  setDocuments,
  documents,
  header,
  disabled = false,
  editMode = true,
}) => {
  const handleFileUpload = (event) => {
    const maxSize = 50 * 1024 * 1024; // 50MB in bytes
    const invalidFiles = [];
    let allFiles = event.target.files;
    const allNames = documents.map((doc) => doc?.name);
    let filtered = [];
    Array.from(allFiles).forEach((file) => {
      if (file.size <= maxSize) {
        if (!allNames.includes(file?.name)) {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          if (
            file?.type?.includes('image') ||
            supportedFiles.includes(fileExtension)
          ) {
            filtered.push(file);
          }
        }
      } else {
        invalidFiles.push(file);
      }
    });
    if (filtered?.length) {
      setDocuments((prev) => [...prev, ...filtered]);
    }
    if (invalidFiles.length) {
      showToast({
        type: 'error',
        message: `The ${invalidFiles?.length === 1 ? 'file' : 'files'} (${invalidFiles
          .map((item) => item.name)
          .join(
            ', '
            // eslint-disable-next-line quotes
          )}) failed to upload as ${invalidFiles?.length === 1 ? "it's" : 'their'} size exceeds the limit. Please select a file smaller than 50MB.`,
      });
    }
    event.target.value = null;
    allFiles = null;
  };

  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        Array.from(event.dataTransfer.files).forEach((file) => {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          if (
            file?.type?.includes('image') ||
            supportedFiles.includes(fileExtension)
          )
            setDocuments((prev) => [...prev, file]);
        });
      }
    },
    [setDocuments]
  );

  return (
    <Stack width={'100%'} gap={2}>
      {header}
      {editMode && (
        <Stack
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          sx={{
            border: (theme) =>
              dragOver
                ? '1px dashed #000'
                : `1px dashed ${theme.palette.text.disabled}`,
            padding: 3,
            textAlign: 'center',
            cursor: 'pointer',
            borderRadius: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            background: dragOver ? '#eee' : '#fafafa',
          }}
        >
          <Icon icon={'material-symbols:upload'} fontSize={32} />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <label htmlFor="upload-image">
              <Button variant="text" component="span">
                <Typography variant="buttonText" color={'text.primary'}>
                  Drag & Drop or Upload Document,{' '}
                  <Typography
                    component="span"
                    variant="buttonText"
                    color={'primary'}
                  >
                    Click here
                  </Typography>
                </Typography>
              </Button>
              <input
                accept="image/*, .pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .txt, .odt, .ott"
                multiple={true}
                id="upload-image"
                hidden
                type="file"
                disabled={disabled}
                onChange={handleFileUpload}
              />
            </label>
            {/* {data?.url && !documents && (
              <a href={data.url} target="_blank" rel="noreferrer">
              <Typography variant="subtitle2" color="primary.main">
              {data.name}
              </Typography>
              </a>
            )} */}
          </Stack>
          <Typography variant="body2">
            PDF, DOCX, DOC, TXT, ODT, OTT or IMAGES (MAX. 50 MB)
          </Typography>
        </Stack>
      )}
      <DisplayDocuments
        showPreview={!editMode}
        editMode={editMode}
        disabled={disabled}
        documents={documents}
        onDeleteDocument={(doc) =>
          setDocuments(
            Array.from(documents).filter((d) =>
              doc?.url ? doc?.url !== d?.url : d.name !== doc.name
            )
          )
        }
      />
    </Stack>
  );
};

UploadDocuments.propTypes = {
  documents: PropTypes.array,
  editMode: PropTypes.any,
  setDocuments: PropTypes.func,
  header: PropTypes.string,
  disabled: PropTypes.bool,
};

export default UploadDocuments;
