import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Icon } from '@iconify/react';
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TableWrapper from '../../../components/common/TableWrapper';
import { adminDefaultSpacing, adminParentListFilter } from '../../../defaults';
import {
  useGetParentsQuery,
  useResendEmailByAdminMutation,
  useVerifyParentEmailMutation,
} from '../../../store/apis/user';
import { debounce, showToast } from '../../../utils/commonFunctions';

const Parents = () => {
  const [anchorElAction, setAnchorElAction] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const navigate = useNavigate();
  const currentTherapist = useRef(null);
  const [resendVerification] = useResendEmailByAdminMutation();
  const [verifyEmail] = useVerifyParentEmailMutation();
  const filter = useMemo(() => {
    const filterObj = {
      search: filterSearchValue,
      page: Number(searchParams.get('page') || 1),
      limit: searchParams.get('limit') || 10,
    };
    if (searchParams.get('status') && searchParams.get('status') !== 'ALL') {
      filterObj.userStatus = searchParams.get('status');
    }
    return filterObj;
  }, [filterSearchValue, searchParams]);
  const setFilteredValue = useCallback((searchQuery) => {
    setFilterSearchValue(searchQuery);
  }, []);

  useEffect(() => {
    debounce(() => {
      setFilteredValue(searchQuery);
      if (filter.page > 1 && searchQuery) setSearchParams({ page: 1 });
    }, 1000);
  }, [searchQuery, setFilteredValue]);

  const handleChangePage = (event, newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSearchParams((prev) => {
      prev.set('page', newPage + 1);
      return prev;
    });
  };

  const handleChangeRowsPerPage = (event) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSearchParams({
      limit: parseInt(event.target.value, 10),
      page: 1,
    });
  };

  const { data: parents, isLoading, isFetching } = useGetParentsQuery(filter);

  const parentStatus = (row) => {
    const status =
      row?.status === 'DELETED'
        ? 'deleted'
        : row?.onboarding?.status === 'NOT_STARTED'
          ? 'notOnboard'
          : row?.onboarding?.status === 'IN_PROGRESS'
            ? 'inProgress'
            : 'completed';

    let color = '';
    let text = '';
    let textColor = '';
    switch (status) {
      case 'notOnboard':
        color = 'warning.light';
        text = 'Onboarding Pending';
        textColor = 'warning.darker';
        break;
      case 'deleted':
        color = 'error.light';
        text = 'Deleted';
        textColor = 'error.darker';
        break;
      case 'inProgress':
        color = 'secondary.light';
        textColor = 'secondary.darker';
        text = 'Onboarding In Progress';
        break;
      case 'completed':
        color = 'primary.contrastText';
        textColor = 'primary.darker';
        text = 'Onboarding Completed';
        break;
      default:
        color = 'secondary';
        text = 'Onboarding Pending';
    }
    return (
      <Chip
        variant="outlined"
        sx={{ backgroundColor: color, color: textColor }}
        label={text}
      />
    );
  };

  const handleCloseAction = (event) => {
    event.stopPropagation();
    setAnchorElAction(null);
  };

  const actions = [
    {
      label: (
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <Icon
            fontSize={22}
            icon={'icon-park-outline:email-successfully'}
          ></Icon>
          <Typography>Verify Email</Typography>
        </Stack>
      ),
      disabled:
        currentTherapist.current?.isEmailVerified ||
        !currentTherapist.current?.email,
      onClick: async function (e) {
        const res = await verifyEmail({
          id: currentTherapist?.current?._id,
          from: 'parent',
        });
        if (res?.data?.status === 'SUCCESS') {
          showToast({
            type: 'success',
            message: res?.data?.message,
          });
        } else {
          showToast({
            type: 'error',
            message: res?.error?.data?.message,
          });
        }
        handleCloseAction(e);
      },
    },
    {
      label: (
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <Icon
            fontSize={22}
            strokeWidth={0.5}
            icon={'icon-park-outline:send-email'}
          ></Icon>
          <Typography>Resend Verification Email</Typography>
        </Stack>
      ),
      disabled:
        currentTherapist.current?.isEmailVerified ||
        !currentTherapist.current?.email,
      onClick: async function (e) {
        const res = await resendVerification({
          id: currentTherapist?.current?._id,
        });
        if (res?.data?.status === 'SUCCESS') {
          showToast({
            type: 'success',
            message: res?.data?.message,
          });
        } else {
          showToast({
            type: 'error',
            message: res?.error?.data?.message,
          });
        }
        handleCloseAction(e);
      },
    },
  ];

  const columns = [
    {
      id: 'name',
      label: 'Name',
      format: (row) => row?.name,
    },
    {
      id: 'info.contactInfo',
      label: 'Contact Info',
      sx: { width: '10rem' },
      format: (row) => (
        <Stack width={'12rem'} direction={'column'}>
          {row?.email && (
            <Tooltip title={row?.email} width={'100%'}>
              <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                <Typography
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  variant="subtitle2"
                >
                  {row?.email}
                </Typography>
              </Stack>
            </Tooltip>
          )}
          {row?.phone && (
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <Typography
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                variant="subtitle2"
              >
                {row?.phone}
              </Typography>
            </Stack>
          )}
        </Stack>
      ),
    },
    {
      id: 'city',
      label: 'City',
      format: (row) => (
        <Typography variant="subtitle2">{row?.location?.city}</Typography>
      ),
    },
    {
      id: 'state',
      label: 'State',
      format: (row) => (
        <Typography variant="subtitle2">{row?.location?.state}</Typography>
      ),
    },
    {
      id: 'country',
      label: 'Country',
      format: (row) => (
        <>
          <Typography variant="subtitle2">{row?.location?.country}</Typography>
        </>
      ),
    },
    {
      id: 'totalChildren',
      label: 'Total Children',
      format: (row) => (
        <Typography variant="subtitle2">
          {row?.kids.length ? row?.kids.length : ''}
        </Typography>
      ),
    },
    {
      id: 'status',
      label: 'Status',
      format: (row) => parentStatus(row),
    },
    {
      id: 'actions',
      label: 'Actions',
      format: (row) => (
        <>
          <Stack
            sx={{ width: '100%', m: 'auto', cursor: 'pointer' }}
            alignItems={'center'}
          >
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                currentTherapist.current = row;
                setAnchorElAction(event.currentTarget);
              }}
              color="secondary"
            >
              <Icon fontSize={24} icon="charm:menu-kebab" />
            </IconButton>
          </Stack>
        </>
      ),
    },
  ];

  const { control } = useForm({
    defaultValues: {
      filter: '',
    },
    mode: 'onChange',
  });

  return (
    <Stack width={'100%'} px={adminDefaultSpacing}>
      <Stack
        alignItems={{ xs: 'flex-start', lg: 'center' }}
        justifyContent={{ xs: 'center', lg: 'space-between' }}
        mb={3}
        gap={2}
        sx={{ flexDirection: { xs: 'column', lg: 'row' } }}
      >
        <Typography variant="h5">Parents</Typography>
        <Stack
          gap={2}
          flexWrap={'wrap'}
          sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          justifyContent={{ xs: 'center', md: 'space-between' }}
        >
          <TextField
            control={control}
            sx={{
              background: (theme) => theme.palette.background.lightBlue,
              borderRadius: '32px',
              minWidth: { xs: '100%', sm: '250px' },
            }}
            InputProps={{
              sx: {
                'borderRadius': '32px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
              },
            }}
            select={true}
            value={searchParams.get('status') || 'ALL'}
            onChange={(e) => {
              setSearchParams((prev) => {
                prev.set('status', e.target.value);
                prev.set('page', 1);
                return prev;
              });
            }}
            options={adminParentListFilter}
          >
            {adminParentListFilter?.map((option, index) => (
              <MenuItem
                style={{ width: '100%' }}
                value={option?.value}
                key={option?.value}
              >
                {option?.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="search"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            value={searchQuery}
            InputProps={{
              startAdornment: (
                <Icon
                  style={{ marginRight: '8px' }}
                  fontSize={24}
                  icon={'carbon:search'}
                />
              ),
              sx: {
                'minWidth': { xs: '100%', sm: '200px' },
                'backgroundColor': (theme) =>
                  theme.palette.background.lightBlue,
                'borderRadius': '32px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
              },
            }}
            placeholder="Search"
          />
        </Stack>
      </Stack>
      {isLoading || isFetching ? (
        <Box
          width="100%"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : parents?.data?.length ? (
        <Stack>
          <TableWrapper
            isCollapsible={false}
            rowKey={'_id'}
            rows={parents?.data || []}
            columns={columns}
            onRowClick={(row) => {
              navigate(`/admin/parents/${row._id}`);
            }}
          />
          <TablePagination
            component="div"
            count={parents?.metadata?.total}
            page={filter.page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={filter.limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      ) : (
        <Box
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          width={'100%'}
          py={10}
        >
          <Typography>No parents found!</Typography>
        </Box>
      )}
      <Menu
        sx={{ mt: '12px' }}
        id="menu-appbar"
        anchorEl={anchorElAction}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left',
        // }}
        keepMounted
        transformOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        open={Boolean(anchorElAction)}
        onClose={handleCloseAction}
        slotProps={{
          paper: {
            elevation: 2,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.32))',
            },
          },
        }}
      >
        {actions.map((action, index) => {
          return (
            <MenuItem
              disabled={action.disabled}
              key={index}
              onClick={(e) => {
                action.onClick(e);
              }}
            >
              {action.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};

export default Parents;
