import React from 'react';

import getRouteWrapper from './getRouteWrapper';

import NotFound from '../pages/not-found/NotFound';
import Home from '../pages/parents/home/Home';
import RefundPolicy from '../pages/refund-policy/RefundPolicy';
const PublicTherapistDetail = React.lazy(
  () => import('../pages/parents/home/PublicTherapistDetail')
);
const SignUp = React.lazy(() => import('../pages/sign-up/SignUp'));
const PublicPageLayout = React.lazy(
  () => import('../layouts/PublicPageLayout')
);
const SignIn = React.lazy(() => import('../pages/sign-in/SignIn'));
const AboutUs = React.lazy(() => import('../pages/about-us/AboutUs'));
const ContactUs = React.lazy(() => import('../pages/contact-us/ContactUs'));
const TermsConditions = React.lazy(
  () => import('../pages/terms-conditions/TermsConditions')
);
const PrivacyPolicy = React.lazy(
  () => import('../pages/privacy-policy/PrivacyPolicy')
);
const EmailSent = React.lazy(
  () => import('../pages/forgot-password/EmailSent')
);
const VerifyEmail = React.lazy(
  () => import('../pages/verify-email/VerifyEmail')
);

const ResetPassword = React.lazy(
  () => import('../pages/reset-password/ResetPassword')
);
const ForgotPassword = React.lazy(
  () => import('../pages/forgot-password/ForgotPassword')
);

const PasswordResetComplete = React.lazy(
  () => import('../pages/forgot-password/PasswordResetComplete')
);

const VerifyToken = React.lazy(() => import('../pages/sign-up/VerifyToken'));
const publicRoutes = [
  {
    path: '/',
    element: getRouteWrapper(<PublicPageLayout />, false),
    children: [
      {
        index: true,
        element: getRouteWrapper(<Home />, false),
      },
      {
        path: '/sign-in',
        element: getRouteWrapper(<SignIn />, false),
      },
      {
        path: '/about-us',
        element: getRouteWrapper(<AboutUs />, false),
      },
      {
        path: '/contact-us',
        element: getRouteWrapper(<ContactUs />, false),
      },
      {
        path: '/terms-conditions',
        element: getRouteWrapper(<TermsConditions />, false),
      },
      {
        path: '/privacy-policy',
        element: getRouteWrapper(<PrivacyPolicy />, false),
      },
      {
        path: '/refund-policy',
        element: getRouteWrapper(<RefundPolicy />, false),
      },
      {
        path: '/sign-up',
        element: getRouteWrapper(<SignUp />, false),
      },
      {
        path: '/forgot-password',
        element: getRouteWrapper(<ForgotPassword />, false),
      },
      {
        path: '/email-sent/:id',
        element: getRouteWrapper(<EmailSent />, false),
      },
      {
        path: '/verify-token',
        element: getRouteWrapper(<VerifyToken />, false),
      },
      {
        path: '/verify-email/:token/:email',
        element: getRouteWrapper(<VerifyEmail />, false),
      },
      {
        path: '/reset-password',
        element: getRouteWrapper(<ResetPassword />, false),
      },
      {
        path: '/password-reset-complete',
        element: getRouteWrapper(<PasswordResetComplete />, false),
      },
      {
        path: '/therapist/:id',
        element: getRouteWrapper(<PublicTherapistDetail />, false),
      },
      {
        path: '/not-found',
        element: getRouteWrapper(<NotFound />),
      },
      {
        path: '*',
        element: getRouteWrapper(<NotFound />),
      },
    ],
  },
  {
    path: '*',
    element: getRouteWrapper(<NotFound />),
  },
];

export default publicRoutes;
