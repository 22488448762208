import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import TransactionDetailModal from './TransactionDetailModal';

import TableWrapper from '../../../components/common/TableWrapper';
import { defaultSpacing } from '../../../defaults';
import useGetUserData from '../../../hooks/useGetUserData';
import {
  useGetTransactionsQuery,
  useLazyGetInvoiceQuery,
} from '../../../store/apis/razorpay';
import {
  debounce,
  paymentStatus,
  showToast,
} from '../../../utils/commonFunctions';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './datePicker.css';

const Transactions = ({
  fromParent = false,
  userType = '',
  fromAdmin = false,
}) => {
  const { id } = useParams();
  const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState({
    open: false,
    data: null,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [isViewInvoice, setViewInvoice] = useState(false);
  const [isDownloadInvoice, setDownloadInvoice] = useState(false);
  const { role } = useGetUserData();
  const [searchParams, setSearchParams] = useSearchParams();
  // const dateRange = useRef({});
  const date = useRef();

  const { control } = useForm({
    mode: 'onChange',
  });

  const statusFilter = [
    { value: 'all', label: 'All' },
    { value: 'captured', label: 'Captured' },
    { value: 'refunded', label: 'Refunded' },
  ];

  const setFilteredValue = useCallback((searchQuery) => {
    setFilterSearchValue(searchQuery);
  }, []);

  useEffect(() => {
    debounce(() => {
      setFilteredValue(searchQuery);
      if (filter.page > 1 && searchQuery) setSearchParams({ page: 1 });
    }, 1000);
  }, [searchQuery, setFilteredValue]);

  const handleChangePage = (event, newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSearchParams((prev) => {
      prev.set('page', newPage + 1);
      return prev;
    });
  };

  const handleChangeRowsPerPage = (event) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSearchParams((prev) => {
      prev.set('limit', parseInt(event.target.value, 10));
      prev.set('page', 1);
      return prev;
    });
  };
  const handleDateRange = (event, picker) => {
    setSearchParams((prev) => {
      prev.set('startDate', picker.startDate.format('MM/DD/YYYY'));
      prev.set('endDate', picker.endDate.format('MM/DD/YYYY'));
      return prev;
    });
  };
  const clearDateRange = (event, picker) => {
    picker.element.val('');
    picker.startDate = dayjs();
    picker.endDate = dayjs();
    setSearchParams((prev) => {
      prev.delete('startDate');
      prev.delete('endDate');
      return prev;
    });
  };
  const filter = useMemo(() => {
    const filterObj = {
      page: Number(searchParams.get('page') || 1),
      limit: searchParams.get('limit') || 10,
      search: filterSearchValue,
      status: searchParams.get('status'),
    };
    if (id) {
      filterObj.userId = id;
    }
    if (userType) filterObj.userType = userType;
    if (searchParams.get('startDate') && searchParams.get('endDate')) {
      filterObj.dateRange = `${dayjs(searchParams.get('startDate')).format('YYYY/MM/DD')}-${dayjs(searchParams.get('endDate')).format('YYYY/MM/DD')}`;
    }
    return filterObj;
  }, [id, searchParams, userType, filterSearchValue]);

  const {
    data: payments,
    isLoading,
    isFetching,
  } = useGetTransactionsQuery(filter);
  const [getInvoice, { originalArgs }] = useLazyGetInvoiceQuery();
  const handleViewInvoice = async (id, paymentId) => {
    try {
      const response = await getInvoice({ id });
      if (response?.isError) {
        if (paymentId) {
          setDownloadInvoice(false);
        } else {
          setViewInvoice(false);
        }
        showToast({ type: 'error', message: response?.error?.data?.message });
        return;
      }
      const blob = await response?.data;
      const url = window.URL.createObjectURL(blob);
      if (paymentId) {
        const link = document.createElement('a');
        link.href = url;
        const fileName = `invoice-${paymentId.split('pay_')[1]}.pdf`;
        link.setAttribute('download', fileName);
        link.click();
        //clean up
        link.remove();
      } else {
        window.open(url, '_blank');
      }
      //clean up
      window.URL.revokeObjectURL(url);
      if (paymentId) {
        setDownloadInvoice(false);
      } else {
        setViewInvoice(false);
      }
    } catch (e) {
      if (paymentId) {
        setDownloadInvoice(false);
      } else {
        setViewInvoice(false);
      }
      showToast({ type: 'error', message: 'Something went wrong!' });
    }
  };
  const getAmount = (row) => {
    return row.amount;
  };
  const columns = [
    {
      id: 'paymentId',
      label: 'Payment Id',
      format: (row) => <Typography variant="subtitle2">{row?.id}</Typography>,
    },

    {
      id: userType === 'THERAPISTS' ? 'parent' : 'therapist',
      label: userType === 'THERAPISTS' ? 'Parent Name' : 'Therapist Name',
      format: (row) => (
        <Stack>
          <Typography variant="subtitle1">
            {userType === 'THERAPISTS'
              ? row?.parentId?.name
              : row?.therapistId?.name}
          </Typography>
          <Typography color={'text.disabled'} variant="subtitle2">
            {userType === 'THERAPISTS'
              ? row?.parentId?.email
              : row?.therapistId?.email}
          </Typography>
          <Typography color={'text.disabled'} variant="subtitle2">
            {userType === 'THERAPISTS'
              ? row?.parentId?.phone
              : row?.therapistId?.phone}
          </Typography>
        </Stack>
      ),
    },
    {
      id: 'amount',
      label: 'Amount',
      format: (row) => `₹ ${(getAmount(row) || 0)?.toFixed(2)}`,
    },
    {
      id: 'method',
      label: 'Payment Mode',
      format: (row) => (
        <Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
          {row?.method}
        </Typography>
      ),
    },
    {
      id: 'date',
      label: 'Created On',
      format: (row) => (
        <>
          <Typography variant="subtitle2">
            {dayjs(row?.createdAt).format('ddd, DD MMM YYYY, hh:mm A')}
          </Typography>
        </>
      ),
    },
    {
      id: 'status',
      label: 'Status',
      format: (row) => paymentStatus(row),
    },
  ];
  if (role === 'parents') {
    columns.push({
      id: 'invoice',
      label: 'Invoice',
      format: (row) => (
        <>
          <IconButton
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              setDownloadInvoice(true);
              handleViewInvoice(row?._id, row?.id);
            }}
          >
            {isDownloadInvoice && originalArgs?.id === row?._id ? (
              <CircularProgress size={24} />
            ) : (
              <Icon icon={'lucide:download'} />
            )}
          </IconButton>
          <IconButton
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              setViewInvoice(true);
              handleViewInvoice(row?._id);
            }}
          >
            {' '}
            {isViewInvoice && originalArgs?.id === row?._id ? (
              <CircularProgress size={24} />
            ) : (
              <Icon icon={'akar-icons:eye'} />
            )}
          </IconButton>
        </>
      ),
    });
  }

  if (fromAdmin)
    columns.splice(2, 0, {
      id: 'parent',
      label: 'Parent Name',
      format: (row) => (
        <Stack>
          <Typography variant="subtitle1">{row?.parentId?.name}</Typography>
          <Typography color={'text.disabled'} variant="subtitle2">
            {row?.parentId?.email}
          </Typography>
          <Typography color={'text.disabled'} variant="subtitle2">
            {row?.parentId?.phone}
          </Typography>
        </Stack>
      ),
    });

  return (
    <Stack width={'100%'} px={fromParent ? defaultSpacing : null}>
      {fromParent && (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography mb={3} variant="h5">
            Transactions
          </Typography>
        </Stack>
      )}
      {!payments?.data?.length &&
      !filter?.search &&
      !filter?.status &&
      !filter?.dateRange ? null : (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'end'}
          gap={2}
          sx={{ mb: 2 }}
        >
          <TextField
            name="search"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            value={searchQuery}
            InputProps={{
              startAdornment: (
                <Icon
                  style={{ marginRight: '8px' }}
                  fontSize={24}
                  icon={'carbon:search'}
                />
              ),
              sx: {
                'backgroundColor': (theme) =>
                  theme.palette.background.lightBlue,
                'borderRadius': '32px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
              },
            }}
            placeholder="Search"
          />
          <TextField
            control={control}
            sx={{
              background: (theme) => theme.palette.background.lightBlue,
              borderRadius: '32px',
              width: '150px',
            }}
            InputProps={{
              sx: {
                'borderRadius': '32px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent !important',
                },
              },
            }}
            select={true}
            value={searchParams.get('status') || 'all'}
            onChange={(e) => {
              setSearchParams((prev) => {
                prev.set('status', e.target.value);
                prev.set('page', 1);
                return prev;
              });
            }}
            options={statusFilter}
          >
            {statusFilter?.map((option, index) => (
              <MenuItem
                style={{ width: '100%' }}
                value={option?.value}
                key={index}
              >
                {option?.label}
              </MenuItem>
            ))}
          </TextField>
          <DateRangePicker
            initialSettings={
              searchParams.get('startDate') && searchParams.get('endDate')
                ? {
                    startDate: searchParams.get('startDate'),
                    endDate: searchParams.get('endDate'),
                  }
                : {}
            }
            ref={date}
            onApply={handleDateRange}
            onCancel={clearDateRange}
          >
            <TextField
              type="text"
              sx={{
                background: (theme) => theme.palette.background.lightBlue,
                borderRadius: '32px',
              }}
              InputProps={{
                sx: {
                  'borderRadius': '32px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent !important',
                  },
                  'cursor': 'pointer',
                },
                readOnly: true,
              }}
              placeholder="Date Filter"
              value={
                searchParams.get('startDate') && searchParams.get('endDate')
                  ? `${dayjs(searchParams.get('startDate')).format('DD/MM/YYYY')} - ${dayjs(searchParams.get('endDate')).format('DD/MM/YYYY')}`
                  : ''
              }
            />
          </DateRangePicker>
        </Stack>
      )}
      {isLoading || isFetching ? (
        <Box
          width="100%"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack>
          {payments?.data?.length ? (
            <>
              <TableWrapper
                isCollapsible={false}
                rowKey={'_id'}
                rows={payments?.data || []}
                columns={columns}
                onRowClick={(row) => {
                  if (!(isDownloadInvoice || isViewInvoice)) {
                    setOpenTransactionDetailModal({ open: true, data: row });
                  }
                }}
              />
              <TablePagination
                component="div"
                count={payments?.metadata?.total}
                page={filter.page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={filter.limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Typography textAlign={'center'} mt={3}>
              No records found
            </Typography>
          )}
        </Stack>
      )}
      <TransactionDetailModal
        key={openTransactionDetailModal.data?.id}
        open={openTransactionDetailModal.open}
        transactionData={openTransactionDetailModal.data}
        fromAdmin={fromAdmin}
        userType={userType}
        handleCancel={() => {
          setOpenTransactionDetailModal((prev) => {
            return { ...prev, open: false };
          });
        }}
      />
    </Stack>
  );
};

Transactions.propTypes = {
  fromParent: PropTypes.bool,
  fromAdmin: PropTypes.bool,
  userType: PropTypes.string,
};

export default Transactions;
