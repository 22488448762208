const fontSize = 14; // px
const htmlFontSize = 16;
const coef = fontSize / 14;

// const fontSizeCalc = (expectedBodyFontSize) => {
//   return (14 / 16) * expectedBodyFontSize;
// };

export const pxToRem = (size) => `${(size / htmlFontSize) * coef}rem`;

export function themeTypography(theme) {
  return {
    htmlFontSize,
    // fontSize: fontSizeCalc(fontSize),
    fontFamily: ['Montserrat-Regular'].join(','),
    h1: {
      fontSize: pxToRem(96),
      fontWeight: 700,
    },
    h2: {
      fontSize: pxToRem(48),
      fontWeight: 700,
    },
    h3: {
      fontSize: pxToRem(48),
      fontWeight: 700,
    },
    h4: {
      fontSize: pxToRem(34),
      fontWeight: 700,
    },
    h5: {
      fontSize: pxToRem(24),
      fontWeight: 700,
    },
    h6: {
      fontSize: pxToRem(20),
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: pxToRem(16),
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: pxToRem(14),
      fontWeight: 400,
    },
    body1: {
      fontSize: pxToRem(16),
      fontWeight: 400,
    },
    body2: {
      fontSize: pxToRem(14),
      fontWeight: 400,
    },
    caption: {
      fontSize: pxToRem(12),
      fontWeight: 400,
    },
    buttonText: {
      fontSize: pxToRem(16),
      fontWeight: 600,
    },
  };
}
