import React from 'react';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  createTheme,
  // responsiveFontSizes,
} from '@mui/material/styles';
import PropTypes from 'prop-types';

import themeColor from './store/theme/index';

export default function ThemeLoader(props) {
  const nonce = process.env.REACT_APP_CSP_NONCE; // Replace with your nonce retrieval mechanism

  const cache = createCache({
    key: 'my-app', // Optional cache key
    nonce,
  });
  // const theme = responsiveFontSizes(createTheme(themeColor));
  const theme = createTheme(themeColor);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CacheProvider value={cache}>{props.children}</CacheProvider>
    </ThemeProvider>
  );
}

ThemeLoader.propTypes = {
  children: PropTypes.element,
};
