import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import EventEmitter from 'eventemitter3';

import config from '../config';

export const newEvent = new EventEmitter();

export const showToast = (data) => {
  newEvent.emit('showToast', data);
};

export const emitLogout = () => {
  newEvent.emit('logout');
};

export const jwtDecode = (t) => {
  if (t.includes('.')) {
    let token = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return token;
  }
  return t;
};

export const setCookie = (cname, cvalue) => {
  if (cvalue) {
    const jwtData = jwtDecode(cvalue);
    const d = jwtData?.payload?.exp
      ? new Date(jwtData?.payload?.exp * 1000).toUTCString()
      : new Date(new Date().getTime() + 60 * 60 * 1000).toUTCString();
    const expires = 'expires=' + d;
    document.cookie =
      cname + '=' + btoa(JSON.stringify(cvalue)) + ';' + expires + ';path=/';
  }
};

export const getUserId = () => {
  const token = getCookie('token') || getCookie('refresh');
  if (token) {
    const jwtData = parseJwt(token);
    return {
      id: jwtData._id || jwtData.aud,
      role: jwtData?.role?.toLowerCase(),
    };
  }
  return '';
};

function parseJwt(token) {
  var base64Url = token.split('.')[1];

  var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const getCookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0 && name.length !== c.length) {
      return atob(c.substring(name.length, c.length));
    }
  }
  return '';
};

export const deleteCookie = (cname) => {
  document.cookie = cname + '=; Path=/;max-age=0';
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    deleteCookie(name);
  }
};

let timeout;
export const debounce = (callback, delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(callback, delay);
};

export const queryParamsBuilder = (query) => {
  if (typeof query !== 'object') {
    return '';
  }
  const keys = Object.keys(query).filter(
    (b) => query[b] !== null && query[b] !== ''
  );
  if (keys.length) {
    return (
      '?' +
      new URLSearchParams(
        keys.reduce((a, b) => {
          a[b] = query[b];
          return a;
        }, {})
      ).toString()
    );
  }
  return '';
};

export function updateCacheData({ state, method, dispatch, extendedApi }, cb) {
  const { api } = state;
  let cacheArgToUpdate = [undefined];
  if (api) {
    cacheArgToUpdate = Object.keys(api.queries)
      .filter(
        (d) =>
          d.indexOf(`${method}(`) === 0 &&
          Object.keys(api.queries[d]).length > 0
      )
      .map((d) => api.queries[d].originalArgs);
  }
  return cacheArgToUpdate.forEach((originalArgs) =>
    dispatch(extendedApi.util.updateQueryData(method, originalArgs, cb))
  );
}

export const toBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getSlotTime = (startTime) => {
  // Parse the start time using Moment.js
  const parsedTime = dayjs(startTime, 'h:mm A');
  // Get the hour from the parsed time
  const startHour = parsedTime.hour();

  // Define the time periods
  if (startHour >= 0 && startHour < 12) {
    return {
      type: 'morning',
      bgColor: 'primary.contrastText',
      textColor: 'primary.darker',
    };
  } else if (startHour >= 12 && startHour < 18) {
    return {
      type: 'afternoon',
      bgColor: 'info.light',
      textColor: 'info.darker',
    };
  } else if (startHour >= 18) {
    return {
      type: 'evening',
      bgColor: 'error.light',
      textColor: 'error.darker',
    };
  }
};

export const getImage = ({ id, role }) => {
  if (id) {
    return `${config.baseUrl}api/${role}/image/${id}`;
  }
  return null;
};

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const paymentStatus = (transactionData) => {
  const status = transactionData?.refund_status || transactionData.status;

  let bgColor = '';
  let textColor = '';
  let text = '';
  switch (status) {
    case 'captured':
      bgColor = 'primary.contrastText';
      textColor = 'primary.darker';
      text = 'Captured';
      break;
    case 'full':
      bgColor = 'error.light';
      textColor = 'error.darker';
      text = 'Full Refunded';
      break;
    case 'partial':
      bgColor = 'error.light';
      textColor = 'error.darker';
      text = `Refunded (${transactionData?.refunds?.length} of ${transactionData?.sessionIds?.length})`;
      break;

    default:
      bgColor = 'primary.contrastText';
      textColor = 'primary.darker';
      text = 'Captured';
  }
  return (
    <Chip
      size="small"
      variant="outlined"
      sx={{ backgroundColor: bgColor, color: textColor }}
      label={text}
    />
  );
};

export const copyToClipboard = async (text) => {
  return await navigator.clipboard.writeText(text);
};

export async function removeMediaDevicesAccess() {
  try {
    let stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    // You may have stored the stream somewhere in your application
    // You can use it to stop tracks
    // For example:
    // stopMediaTracks(stream);

    // Or directly stop tracks from the stream
    stream.getTracks().forEach((track) => {
      if (track) {
        track.stop(); // Stop the track
      }
    });

    // Once stopped, remove references to the stream
    stream = null;

    console.log('Media devices access removed successfully.');
  } catch (error) {
    console.error('Error removing media devices access:', error);
  }
}

export const maskPart = (str) => {
  if (str.length <= 4) {
    return str;
  }
  return `${str.substring(0, 2)}${'*'.repeat(str.length - 4)}${str.substring(
    str.length - 2
  )}`;
};
