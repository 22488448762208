import React from 'react';

import { Icon } from '@iconify/react';
import { CircularProgress, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import UserAvatar from './UserAvatar';

import config from '../../config';
import { getUserId } from '../../utils/commonFunctions';
export const validateFile = (file) => {
  let message = '';
  let img = new Image();
  if (file) {
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.height < 100 || img.width < 100) {
        message = 'Image should have a minimum resolution of 100 x 100 pixels';
        return message;
      }
    };

    const fileExtension = file.name.split('.').at(-1);
    const allowedFileTypes = ['jpeg', 'png', 'jpg', 'gif', 'webp'];
    if (!allowedFileTypes.includes(fileExtension)) {
      message = `File does not support. Files type must be ${allowedFileTypes.join(
        ', '
      )}`;

      return message;
    }
    if (file.size > 2e6) {
      message = 'Image size must not exceed 2 MB';
      return message;
    }
    return message;
  }
};

const UploadImage = ({
  setImageUrl,
  imageUrl,
  imageLoading = false,
  editMode,
}) => {
  const { id } = getUserId();
  const handleChange = React.useCallback(
    (e) => {
      const file = e.target.files[0];

      const message = validateFile(file);
      if (message) {
        window.alert(
          'Image should have a minimum resolution of 100 x 100 pixels'
        );
        return false;
      }
      if (e.target.files.length) {
        // setImageUrl(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrl({ url: URL.createObjectURL(file), file });
        };
        reader.readAsDataURL(file);
      }
    },
    [setImageUrl]
  );
  const img = !imageUrl?.url?.includes('images/')
    ? imageUrl?.url
    : `${config.baseUrl}api/users/document?url=${
        imageUrl?.url
      }&token=${btoa(id)}`;
  return (
    <>
      {imageLoading ? (
        <CircularProgress size={30} />
      ) : (
        <Stack width={'fit-content'} position="relative" display={'flex'}>
          <UserAvatar src={img} size={172} />
          {editMode && (
            <Stack
              sx={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                //   height: '100px',
                //   width: '100px',
                bottom: 0,
                top: 0,
                right: 0,
                left: 0,
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 99,
              }}
            >
              <IconButton
                component="label"
                sx={{
                  width: 'fit-content',
                  background: img ? 'rgba(0,0,0,0.5)' : 'transparent',
                  color: (theme) =>
                    img ? theme.palette.common.white : theme.palette.text.title,
                }}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleChange}
                />
                <Icon icon={'ph:camera'} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};

UploadImage.propTypes = {
  editMode: PropTypes.any,
  imageLoading: PropTypes.bool,
  imageUrl: PropTypes.shape({
    url: PropTypes.any,
  }),
  setImageUrl: PropTypes.func,
};

export default UploadImage;
