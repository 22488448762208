import * as React from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import coablesLogo from './../../assets/svg/coable-logo.svg';
import ConfirmationModal from './ConfirmationModal';
import UserAvatar from './UserAvatar';

import defaultImage from '../../assets/images/default-image.jpg';
import config from '../../config';
import { useAuth } from '../../context/AuthProvider';
import { defaultSpacing } from '../../defaults';
import useGetUserData from '../../hooks/useGetUserData';
import { useDeleteAccountMutation } from '../../store/apis/auth';
import { showToast } from '../../utils/commonFunctions';
const drawerWidth = 280;

export default function Header(props) {
  const { data: userData, role } = useGetUserData();
  const [anchorElTour, setAnchorElTour] = React.useState(null);
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    React.useState(false);

  const handleOpenTourMenu = (event) => {
    setAnchorElTour(event.currentTarget);
  };

  const handleCloseTourMenu = () => {
    setAnchorElTour(null);
  };
  const handleTourOptions = (role) => {
    handleTakeATour(role);
    props.setTourFor(role);
    setMobileOpen(false);
  };

  const tourOptions = [
    {
      label: 'Registration Journey',
      icon: 'lets-icons:user-box',
      onClick: () => {
        handleTourOptions('public');
      },
    },
    {
      label: 'Parent Journey',
      icon: 'mdi:television-guide',
      onClick: () => {
        handleTourOptions('parents');
      },
    },
    {
      label: 'Therapist Journey',
      icon: 'uiw:logout',
      onClick: () => {
        handleTourOptions('therapists');
      },
    },
  ];

  //user profile image
  const handleTakeATour = (role) => {
    const tourData = JSON.parse(localStorage.getItem('tourData')) || [];
    tourData[role] = true;
    localStorage.setItem('tourData', JSON.stringify(tourData));
    props.setIsTourOpen(tourData);
  };

  const img = !userData?.profileImage?.includes('images/')
    ? userData?.profileImage
    : `${config.baseUrl}api/users/document?url=${
        userData?.profileImage
      }&token=${btoa(userData?._id)}`;
  const auth = useAuth();

  const navItems = !auth.authenticated
    ? [
        {
          title: 'About Us',
          path: '/about-us',
        },
        {
          title: 'Contact Us',
          path: '/contact-us',
        },
        {
          title: 'Take a Tour',
          onClick: (event) => {
            event.stopPropagation();
            handleOpenTourMenu(event);
          },
        },
        {
          title: 'Sign in',
          path: '/sign-in',
        },
      ]
    : role === 'parents'
      ? userData?.onboarding?.status === 'COMPLETED'
        ? [
            {
              title: 'Home',
              path: '/',
              icon: 'ant-design:home-outlined',
            },
            {
              title: 'Calendar',
              path: '/parents/calendar',
              icon: 'formkit:datetime',
            },
            {
              title: 'Sessions',
              path: '/parents/sessions',
              icon: 'fa6-regular:calendar-check',
            },
            {
              title: 'Transactions History',
              path: '/parents/transactions-history',
              icon: 'mdi:account-payment-outline',
            },
            {
              title: 'About Us',
              path: '/about-us',
              icon: 'tabler:info-square',
            },
            {
              title: 'Contact Us',
              path: '/contact-us',
              icon: 'ci:mail',
            },
            {
              title: 'Take a tour',
              icon: 'mdi:television-guide',
              onClick: () => {
                handleTakeATour(role);
              },
            },
          ]
        : [
            {
              title: 'About Us',
              path: '/about-us',
            },
            {
              title: 'Contact Us',
              path: '/contact-us',
            },
            {
              title: 'Take a tour',
              icon: 'mdi:television-guide',
              onClick: () => {
                handleTakeATour(role);
              },
            },
          ]
      : userData?.onboarding?.adminStatus === 'APPROVED'
        ? [
            {
              title: 'Dashboard',
              path: '/therapists/dashboard',
              icon: 'lucide:layout-dashboard',
            },
            {
              title: 'Calendar',
              path: '/therapists/calendar',
              icon: 'formkit:datetime',
            },
            {
              title: 'Sessions',
              path: '/therapists/sessions',
              icon: 'fa6-regular:calendar-check',
            },
            {
              title: 'Transactions History',
              path: '/therapists/transactions-history',
              icon: 'mdi:account-payment-outline',
            },
            {
              title: 'About Us',
              path: '/about-us',
              icon: 'tabler:info-square',
            },
            {
              title: 'Contact Us',
              path: '/contact-us',
              icon: 'ci:mail',
            },
            {
              title: 'Take a tour',
              icon: 'mdi:television-guide',
              onClick: () => {
                handleTakeATour(role);
              },
            },
          ]
        : [
            {
              title: 'About Us',
              path: '/about-us',
              icon: 'tabler:info-square',
            },
            {
              title: 'Contact Us',
              path: '/contact-us',
              icon: 'ci:mail',
            },
            {
              title: 'Take a tour',
              icon: 'mdi:television-guide',
              onClick: () => {
                handleTakeATour(role);
              },
            },
          ];

  const settings = [
    {
      label: 'Profile',
      icon: 'lets-icons:user-box',
      onClick: () => {
        navigate(`/${role}/profile`);
      },
    },
    {
      label: 'Delete Account',
      icon: 'mdi:account-remove-outline',
      onClick: () => {
        setShowDeleteAccountModal(true);
      },
    },
    {
      label: 'Logout',
      icon: 'uiw:logout',
      onClick: () => {
        auth.removeAuth();
      },
    },
  ];
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();

  const handleRedirect = (item, event) => {
    if (item?.onClick) {
      item.onClick(event);
    } else if (item?.path) {
      navigate(item.path);
    }
  };

  const [deleteAccount, { isLoading: isDeleting }] = useDeleteAccountMutation();

  const handleDeleteAccount = async () => {
    const res = await deleteAccount();
    if (res?.error) {
      showToast({
        type: 'error',
        message:
          res?.error?.data?.message ||
          'Something went wrong. Please try later!',
      });
    }

    if (res?.data) {
      setShowDeleteAccountModal(false);
      auth.removeAuth();
      showToast({
        type: 'success',
        message: 'Your account has been deleted successfully!',
      });
    }
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center',
        height: '100%',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ flexGrow: 100 }}>
        <Box
          sx={{
            cursor: 'pointer',
            py: 4,
            bgcolor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          {auth.authenticated ? (
            <>
              <UserAvatar
                alt={userData?.name}
                src={img || defaultImage}
                size={82}
              />
              <Typography variant="h6">{userData?.name}</Typography>
              <Typography variant="caption" sx={{ wordWrap: 'break-word' }}>
                {userData?.email ? userData?.email : userData?.phone}
              </Typography>
            </>
          ) : (
            <img
              width={'185px'}
              height={'60px'}
              style={{ objectFit: 'contain' }}
              src={coablesLogo}
              alt="coables-logo"
            />
          )}
        </Box>
        <Divider />
        <List>
          {navItems.map((item) => (
            <ListItem
              key={item.title}
              disablePadding
              onClick={(event) => handleRedirect(item, event)}
            >
              <ListItemButton
                sx={{
                  textAlign: auth.authenticated ? 'left' : 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '12px',
                  color: (theme) =>
                    location.pathname === `${item.path}`
                      ? theme.palette.primary.dark
                      : 'text.secondary',
                }}
              >
                <Icon height={24} width={24} icon={item.icon}></Icon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      variant={
                        location?.pathname === item.path
                          ? 'buttonText'
                          : 'body1'
                      }
                    >
                      {item.title}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}

          {!auth.authenticated && (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                navigate('/sign-up');
              }}
            >
              Sign up
            </Button>
          )}
          {auth.authenticated &&
            settings.map((item) =>
              item.label !== 'Logout' ? (
                <ListItem
                  key={item.label}
                  disablePadding
                  onClick={() => item.onClick()}
                >
                  {
                    <ListItemButton
                      sx={{
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '12px',
                        color: (theme) =>
                          location.pathname ===
                          `/${role}/${item.label}`.toLowerCase()
                            ? theme.palette.primary.dark
                            : 'text.secondary',
                      }}
                    >
                      <Icon height={24} width={24} icon={item.icon}></Icon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            color={item.label === 'Logout' ? 'text.error' : ''}
                            variant={
                              location?.pathname ===
                              `/${role}/${item.label}`.toLowerCase()
                                ? 'buttonText'
                                : 'body1'
                            }
                          >
                            {item.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  }
                </ListItem>
              ) : (
                <></>
              )
            )}
        </List>
      </Box>
      {auth.authenticated && (
        <ListItemButton
          onClick={() => {
            auth.removeAuth();
          }}
          sx={{
            border: 'red 2px solid',
            borderRadius: '4px',
            width: '95%',
            m: '12px 2.5%',
            color: (theme) => theme.palette.error.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <Icon fontSize={24} icon={'uiw:logout'} />
          <Typography variant="buttonText">Logout</Typography>
        </ListItemButton>
      )}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          boxShadow: '0px 4px 30px 0px #0000000D',
          px: defaultSpacing,
          py: 1,
          backgroundColor: (theme) => theme.palette.common.white,
        }}
      >
        <Toolbar sx={{ px: '0 !important' }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: {
                lg: 'none',
                md: !auth.authenticated ? 'none' : 'block',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: 'block',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            <img
              style={{ objectFit: 'contain' }}
              src={coablesLogo}
              width={'185px'}
              height={'60px'}
              alt="coables-logo"
            />
          </Box>
          <Box
            gap={3}
            sx={{
              display: {
                xs: 'none',
                md: auth.authenticated ? 'none' : 'flex',
                lg: 'flex',
                flexDirection: 'row',
                flexGrow: auth.authenticated ? 1.2 : 0,
              },
            }}
          >
            {navItems.map((item) => (
              <React.Fragment key={item.id}>
                <Stack>
                  <Button
                    // key={item.title}
                    onClick={(event) => handleRedirect(item, event)}
                    sx={{
                      'color': (theme) =>
                        location?.pathname === item.path
                          ? theme.palette.primary.darker
                          : theme.palette.text.secondary,
                      // 'px': '18px !important',
                      // 'backgroundColor': (theme) =>
                      //   location?.pathname === item.path
                      //     ? theme.palette.primary.light
                      //     : '',
                      // ':hover': {
                      //   backgroundColor: (theme) =>
                      //     location?.pathname !== item.path
                      //       ? '#efffb9 !important'
                      //       : theme.palette.primary.light,
                      // },
                      ':hover': {
                        color: (theme) => theme.palette.primary.darker,
                      },
                      '::after': {
                        backgroundColor: (theme) =>
                          theme.palette.primary.darker,
                        bottom: 0,
                        content: '""',
                        height: '1px',
                        left: 0,
                        position: 'absolute',
                        transform: 'scaleX(0)',
                        transformOrigin: 'bottom right',
                        transition: 'transform .3s ease-out',
                        width: '100%',
                      },
                      ':hover::after': {
                        transform: 'scaleX(1)',
                        transformOrigin: 'bottom left',
                      },
                    }}
                  >
                    <Typography>{item.title}</Typography>
                  </Button>
                </Stack>
              </React.Fragment>
            ))}
          </Box>
          <Box
            gap={3}
            sx={{
              display: {
                xs: 'none',
                md: auth.authenticated ? 'none' : 'block',
                lg: 'flex',
                flexDirection: 'row',
              },
            }}
          >
            {!auth.authenticated ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate('/sign-up');
                  }}
                  sx={{ ml: '16px' }}
                >
                  <Typography
                    color={(theme) => theme.palette.text.primary}
                    variant="body1"
                  >
                    {'Sign up'}
                  </Typography>
                </Button>
                <Menu
                  sx={{ mt: '45px' }}
                  id="tour-menu"
                  anchorEl={anchorElTour}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElTour)}
                  onClose={handleCloseTourMenu}
                  slotProps={{
                    paper: {
                      elevation: 2,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.32))',
                      },
                    },
                  }}
                >
                  {tourOptions.map((option) => (
                    <MenuItem
                      key={option.label}
                      onClick={() => {
                        option.onClick();
                        handleCloseTourMenu();
                      }}
                    >
                      <Typography textAlign="center">{option.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <Stack>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <UserAvatar
                      alt={userData?.name}
                      src={img || defaultImage}
                      size={45}
                    />
                    {/* <Avatar alt={userData?.name} src={img} /> */}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ 'mt': '45px', '& .MuiList-root': { p: 0 } }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  slotProps={{
                    paper: {
                      elevation: 2,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.32))',
                      },
                    },
                  }}
                >
                  <Stack p={2} gap={0.4}>
                    <Typography variant="subtitle1">
                      {userData?.name}
                    </Typography>
                    {userData?.email && (
                      <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Icon icon={'dashicons:email'} />
                        <Typography variant="body2">
                          {userData?.email}
                        </Typography>
                      </Stack>
                    )}
                    {userData?.phone && (
                      <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Icon icon={'ri:phone-fill'} />
                        <Typography variant="body2">
                          {userData?.phone}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Divider />
                  <Stack sx={{ py: 1 }}>
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting.label}
                        onClick={() => {
                          setting.onClick();
                          handleCloseUserMenu();
                        }}
                      >
                        <Stack
                          direction={'row'}
                          gap={2}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Icon
                            height={20}
                            width={20}
                            style={{ display: 'flex' }}
                            icon={setting.icon}
                          />
                          <Typography textAlign="center">
                            {setting.label}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Stack>
                </Menu>
              </Stack>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            'display': { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <ConfirmationModal
        open={showDeleteAccountModal}
        title={'Are you sure you want to permanently delete this account?'}
        handleSubmit={handleDeleteAccount}
        handleCancel={() => setShowDeleteAccountModal(false)}
        isLoading={isDeleting}
      />
    </Box>
  );
}

Header.propTypes = {
  window: PropTypes.func,
  setIsTourOpen: PropTypes.func,
  isTourOpen: PropTypes.any,
  setTourFor: PropTypes.func,
};
