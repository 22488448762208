import * as React from 'react';

import { Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export default function BasicBreadcrumbs({ links, currentPage }) {
  const navigate = useNavigate();

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {(links || []).map((link) => (
          <Link
            key="link.path"
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate(link.path);
            }}
            sx={{ cursor: 'pointer' }}
          >
            {link.title}
          </Link>
        ))}
        <Typography color="text.primary">{currentPage}</Typography>
      </Breadcrumbs>
    </div>
  );
}

BasicBreadcrumbs.propTypes = {
  currentPage: PropTypes.any,
  links: PropTypes.array,
};
