import { createApiInstance } from './createApiInstance';

import { queryParamsBuilder } from '../../utils/commonFunctions';

const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.mutation({
      query({ usertype, ...body }) {
        body.password = btoa(body.password);
        return {
          url: '/auth/login',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    signInInit: build.query({
      query({ body }) {
        return {
          url: '/auth/login/init',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => res,
    }),
    signInValidate: build.mutation({
      query({ body }) {
        console.log('body', body);
        if (body.password) body.password = btoa(body.password);
        return {
          url: '/auth/login/validate',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => res,
    }),
    resendOtp: build.mutation({
      query({ body, type }) {
        return {
          url: `/auth/resend-otp/${type}`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => res,
    }),

    signUpInit: build.query({
      query({ role, body }) {
        return {
          url: `/auth/register/init/${role}`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => res,
    }),
    signUpValidate: build.mutation({
      query({ role, body }) {
        console.log(body);
        if (body.password) body.password = btoa(body.password);
        return {
          url: `/auth/register/validate/${role}`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => res,
    }),
    signUp: build.mutation({
      query({ usertype, body }) {
        body.password = btoa(body.password);
        return {
          url: `/auth/register/${usertype}`,
          method: 'POST',
          body: { ...body },
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    forgotPassword: build.mutation({
      query(body) {
        return {
          url: '/auth/forgot-password',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    VerifyToken: build.query({
      query: ({ token }) => `/auth/verify-token/${token}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    resendVerification: build.query({
      query(query) {
        return {
          url: `/auth/resend-email-verification${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },

      transformResponse: (res) => {
        return res;
      },
    }),
    deleteAccount: build.mutation({
      query() {
        return {
          url: '/api/users',
          method: 'DELETE',
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    resendForgetPasswordVerification: build.query({
      query(query) {
        return {
          url: `/auth/resend-forgot-password-email${queryParamsBuilder(query)}`,
          method: 'GET',
        };
      },

      transformResponse: (res) => {
        return res;
      },
    }),
    ResetPassword: build.mutation({
      query(body) {
        body.password = btoa(body.password);
        return {
          url: '/auth/reset-password',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useVerifyTokenQuery,
  useResetPasswordMutation,
  useLazyResendVerificationQuery,
  useLazyResendForgetPasswordVerificationQuery,
  useLazySignUpInitQuery,
  useSignUpValidateMutation,
  useLazySignInInitQuery,
  useSignInValidateMutation,
  useResendOtpMutation,
  useDeleteAccountMutation,
} = extendedApi;
