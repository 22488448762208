import React from 'react';

import { Navigate } from 'react-router-dom';

import getRouteWrapper from './getRouteWrapper';

import AuthLayout from '../layouts/AuthLayout';
import AboutUs from '../pages/about-us/AboutUs';
import ContactUs from '../pages/contact-us/ContactUs';
import Transactions from '../pages/parents/transactions/Transactions';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import RefundPolicy from '../pages/refund-policy/RefundPolicy';
import VerifyToken from '../pages/sign-up/VerifyToken';
import TermsConditions from '../pages/terms-conditions/TermsConditions';
const Sessions = React.lazy(() => import('../pages/parents/sessions/Sessions'));
const VideoSdk = React.lazy(() => import('../video-sdk/VideoSdk'));

const NotFound = React.lazy(() => import('../pages/not-found/NotFound'));
const TherapistDashboard = React.lazy(
  () => import('../pages/therapist/therapist-dashboard/TherapistDashboard')
);
const Home = React.lazy(() => import('../pages/parents/home/Home'));

const Profile = React.lazy(() => import('../pages/therapist/profile/Profile'));
const BeingReviewed = React.lazy(
  () => import('../pages/therapist/being-reviewed/BeingReviewed')
);
const Onboarding = React.lazy(
  () => import('../pages/therapist/onboarding/Onboarding')
);
const Calendar = React.lazy(() => import('../pages/parents/calendar/Calendar'));
const PublicTherapistDetail = React.lazy(
  () => import('../pages/parents/home/PublicTherapistDetail')
);
const therapistsRoutes = [
  {
    path: '/therapists',
    element: getRouteWrapper(<AuthLayout />),
    children: [
      {
        index: true,
        element: <Navigate to="dashboard" />,
      },
      {
        path: 'dashboard',
        element: getRouteWrapper(<TherapistDashboard />),
      },
      {
        path: 'onboarding',
        element: getRouteWrapper(<Onboarding />),
      },
      {
        path: 'being-review',
        element: getRouteWrapper(<BeingReviewed />),
      },
      {
        path: 'profile',
        element: getRouteWrapper(<Profile />),
      },
      {
        path: 'calendar',
        element: getRouteWrapper(<Calendar />),
      },
      {
        path: 'sessions',
        element: getRouteWrapper(<Sessions />),
      },
      {
        path: 'join-meeting',
        element: getRouteWrapper(<VideoSdk />),
      },
      {
        path: 'transactions-history',
        element: getRouteWrapper(
          <Transactions userType="THERAPISTS" fromParent={true} />
        ),
      },
      {
        path: '*',
        element: getRouteWrapper(<NotFound />),
      },
    ],
  },
  {
    path: '/about-us',
    element: getRouteWrapper(<AboutUs />, false),
  },
  {
    path: '/contact-us',
    element: getRouteWrapper(<ContactUs />, false),
  },
  {
    path: '/terms-conditions',
    element: getRouteWrapper(<TermsConditions />, false),
  },
  {
    path: '/privacy-policy',
    element: getRouteWrapper(<PrivacyPolicy />, false),
  },
  {
    path: '/refund-policy',
    element: getRouteWrapper(<RefundPolicy />),
  },
  {
    path: '/verify-token',
    element: getRouteWrapper(<VerifyToken />),
  },
  {
    path: '/',
    element: getRouteWrapper(<AuthLayout />, false),
    children: [
      {
        index: true,
        element: getRouteWrapper(<Home />, false),
      },
      {
        path: '/therapist/:id',
        element: getRouteWrapper(<PublicTherapistDetail />, false),
      },
    ],
  },

  {
    path: '*',
    element: getRouteWrapper(<NotFound />, false),
  },
];

export default therapistsRoutes;
