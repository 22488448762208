/* eslint-disable no-unused-vars */
import * as React from 'react';

import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

export default function TableWrapper({
  columns,
  rows,
  rowKey,
  onRowClick,
  isCollapsible = true,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortConfig, setSortConfig] = React.useState({
    key: searchParams.get('sort')?.split(':')[0] || null,
    direction: searchParams.get('sort')?.split(':')[1] || 'asc',
  });

  React.useEffect(() => {
    console.log(sortConfig);
  }, [sortConfig]);

  const [open, setOpen] = React.useState(true);

  const onSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setSearchParams((prev) => {
      prev.set('sort', `${key}:${direction}`);
      prev.set('page', 1);
      return prev;
    });
  };

  // Function to get nested property value
  const getNestedPropertyValue = (obj, key) => {
    const keys = key.split('.');
    let value = obj;
    for (let k of keys) {
      value = value?.[k];
    }
    return value;
  };

  return (
    <>
      <TableContainer sx={{ overflow: 'auto' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns
                .filter((col) => col.id !== 'collapseContent')
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={column.sx}
                    onClick={
                      column.sortable ? () => onSort(column.id) : undefined
                    }
                    style={{ cursor: column.sortable ? 'pointer' : 'default' }}
                  >
                    <Typography variant="subtitle2" fontWeight="fontWeightBold">
                      {column.label}
                      {column.sortable &&
                        sortConfig.key === column.id &&
                        (sortConfig.direction === 'asc' ? ' ▲' : ' ▼')}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <React.Fragment key={row[rowKey]}>
                  <TableRow
                    sx={{
                      cursor:
                        onRowClick || isCollapsible ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                      if (!isCollapsible && onRowClick) {
                        onRowClick(row);
                      } else {
                        if (isCollapsible) {
                          setOpen((prev) =>
                            prev === row[rowKey] ? null : row[rowKey]
                          );
                        }
                      }
                    }}
                    role="checkbox"
                    tabIndex={-1}
                    key={row[rowKey]}
                  >
                    {columns
                      .filter((col) => col.id !== 'collapseContent')
                      .map((column) => {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={column.sx}
                          >
                            {column.format(row)}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                  {isCollapsible && (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={columns?.length - 1}
                      >
                        <Collapse
                          in={open === row[rowKey]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            {columns
                              ?.find((col) => col.id === 'collapseContent')
                              ?.format(row)}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

TableWrapper.propTypes = {
  isCollapsible: PropTypes.bool,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  rowKey: PropTypes.any,
  rows: PropTypes.array,
};
