import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

export const HookAutoComplete = ({
  label,
  control,
  name,
  valueKey = 'value',
  required = false,
  disabled = false,
  options = [],
  readOnly = false,
  isLoading = false,
  sx = {},
  multiple = true,
}) => {
  const { field, fieldState, formState } = useController({ name, control });

  return (
    <Autocomplete
      loading={isLoading}
      readOnly={readOnly}
      fullWidth
      disabled={disabled}
      multiple={multiple}
      // freeSolo
      isOptionEqualToValue={(op, value) => {
        return op?._id === value?._id;
      }}
      options={options}
      {...field}
      // value={field.value}
      onChange={(_, data) => {
        field.onChange(data);
      }}
      sx={{
        '& .MuiInputLabel-outlined': {
          color: (theme) => theme.palette.text.primary,
        },
        ...sx,
      }}
      getOptionLabel={(op) => {
        return op?.name || op;
      }}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label={!required ? label : label + ' *'}
          // required={field.value.length === 0}
          error={
            (fieldState?.isTouched || formState?.isSubmitted) &&
            Boolean(fieldState?.error)
          }
          helperText={
            (fieldState?.isTouched || formState?.isSubmitted) &&
            fieldState?.error?.message
          }
        />
      )}
    />
  );
};

HookAutoComplete.propTypes = {
  control: PropTypes.any,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.any,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  sx: PropTypes.object,
  valueKey: PropTypes.string,
};
