import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import contactus from '../../assets/svg/contact-us.svg';
import ButtonWithLoading from '../../components/common/ButtonWithLoading';
import { HookTextField } from '../../components/hook-form-fields/HookTextField';
import { useSendContactUsMutation } from '../../store/apis/contactus';
import { showToast } from '../../utils/commonFunctions';
import validationSchemas from '../../utils/validationSchemas';
const validationSchema = yup.object({
  name: validationSchemas.name,
  email: validationSchemas.usernameEmail,
  message: validationSchemas.message,
});

export default function ContactUs() {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const [sendContactUsmessage, { data, isLoading, isSuccess, isError, error }] =
    useSendContactUsMutation();

  useEffect(() => {
    if (isSuccess && !isLoading && data?.message) {
      showToast({
        type: 'success',
        message: data?.message,
      });
      reset();
    }
  }, [data?.message, isLoading, isSuccess, reset]);

  useEffect(() => {
    if (isError) {
      showToast({ type: 'error', message: error?.data?.message });
      return;
    }
  }, [error?.data?.message, isError]);

  const onSubmit = useCallback(
    async (data) => {
      sendContactUsmessage({ body: data });
    },
    [sendContactUsmessage]
  );

  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ flexDirection: 'column', px: 2 }}
    >
      <Grid
        alignItems={'center'}
        container
        item
        lg={8}
        sx={{
          p: 2,
          boxShadow: '0px 4px 30px 0px #0000000D',
          backgroundColor: 'white',
          borderRadius: 4,
        }}
        justifyContent={'center'}
      >
        <Grid
          order={{ xs: 2, lg: 1 }}
          item
          xs={12}
          lg={6}
          sx={{
            p: { xs: 0, sm: 2 },
            pt: { xs: 3, sm: 0 },
          }}
          px={4}
        >
          <Stack>
            <Typography
              sx={{
                fontSize: 40,
                fontWeight: 900,
                color: (theme) => theme.palette.primary.dark,
              }}
            >
              Let&rsquo;s talk
            </Typography>
            <Typography
              sx={{
                color: (theme) => theme.palette.secondary.dark,
              }}
              mt={2}
              mb={4}
              variant="subtitle1"
            >
              We value your feedback, suggestions, and reviews on how we can
              better serve you. We are always listening.
            </Typography>
          </Stack>
          <form
            onSubmit={handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            <Stack spacing={3}>
              <Stack spacing={3}>
                <HookTextField control={control} name="name" label="Name" />
                <HookTextField
                  control={control}
                  name="email"
                  label="Email address"
                />
                <HookTextField
                  control={control}
                  name="message"
                  label="How can we help?"
                  multiline
                  rows={10}
                />
              </Stack>
              <Stack alignItems="flex-start">
                <ButtonWithLoading
                  type="submit"
                  buttonTextVariant="body1"
                  isLoading={isLoading}
                  title={'Send message'}
                  customStyle={{
                    width: 'auto',
                    px: 2,
                    borderRadius: 2,
                  }}
                />
              </Stack>
            </Stack>
          </form>
        </Grid>
        <Grid
          sx={{
            width: { xs: '100%', sm: '80%', md: '60%' },
          }}
          item
          lg={6}
          px={2}
          order={{ xs: 1, lg: 2 }}
          justifyContent={'center'}
        >
          <Stack
            sx={{
              borderRadius: 4,
              overflow: 'hidden',
              height: '100%',
              width: '100%',
            }}
          >
            <img
              alt="contact us"
              height={'auto'}
              width={'100%'}
              style={{ objectFit: 'fill', borderRadius: 2 }}
              src={contactus}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}
