import React, { useMemo, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';

import defaultImage from '../../assets/images/default-image.jpg';
function UserAvatar({ alt, src, size, variant = 'rounded' }) {
  const [isLoading, setIsLoading] = useState(true);
  const variantObj = useMemo(
    () => ({
      rounded: '50%',
      square: 0,
      circular: size,
    }),
    [size]
  );

  return src ? (
    <>
      <img
        src={src}
        alt="avatar"
        onError={(current) => {
          current.target.src = defaultImage;
        }}
        onLoad={() => setIsLoading(false)}
        style={{
          aspectRation: 1,
          height: size,
          width: size,
          minWidth: size,
          borderRadius: variantObj[variant],
          objectFit: 'cover',
          zIndex: 99,
          bgcolor: (theme) => theme.palette.grey[100],
        }}
      />
      {isLoading ? (
        <Box
          sx={{
            width: size,
            height: size,
            fontSize: size * 0.5,
            bgcolor: (theme) => theme.palette.grey[100],
            position: 'absolute',
            borderRadius: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            sx={{ color: (theme) => theme.palette.text.primary }}
            size={16}
          />
        </Box>
      ) : null}
    </>
  ) : (
    <Avatar
      variant={variant}
      sx={{
        width: size,
        height: size,
        fontSize: size * 0.5,
        bgcolor: (theme) => theme.palette.grey[100],
      }}
      alt={alt}
      src={'/'}
    />
  );
}

UserAvatar.propTypes = {
  alt: PropTypes.string,
  variant: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.number,
};

export default UserAvatar;
