import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';

import Inclusion from '../../assets/images/about-inclusion.jpeg';
import OnlineSession from '../../assets/images/about-online-session.jpeg';
import Professionals from '../../assets/images/about-professionals.jpeg';
import handshake from '../../assets/images/handshake.png';

const AboutUs = () => {
  return (
    <Stack
      sx={{
        flex: 1,
        height: '100%',
      }}
    >
      <Grid container justifyContent={'center'} sx={{ px: 2 }}>
        <Grid
          item
          xl={6}
          md={8}
          sx={{
            boxShadow: '0px 4px 30px 0px #0000000D',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          <Stack
            sx={{
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              overflow: 'hidden',
            }}
          >
            <img
              alt="about us"
              height={'auto'}
              width={'100%'}
              style={{
                objectFit: 'cover',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              src={handshake}
            />
          </Stack>
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', px: 2 }}
            my={4}
            lineHeight={1.5}
          >
            Welcome to The Blessed Ones and Coables, your trusted partners in
            specialized therapies for children with Autism, ADHD, Down syndrome,
            cerebral palsy, GDD, learning disabilities, and related disorders.
          </Typography>
          <Grid
            container
            item
            alignItems={'center'}
            my={4}
            spacing={3}
            sx={{
              px: 2,
            }}
          >
            <Grid item xs={12} sm={5}>
              <Stack
                sx={{
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
              >
                <img
                  alt="carousel"
                  height={'auto'}
                  width={'100%'}
                  style={{ objectFit: 'cover', borderRadius: 4 }}
                  src={OnlineSession}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography sx={{ textAlign: 'center' }} variant="subtitle1">
                Coables takes therapy a step further by offering convenient
                online sessions through our cutting-edge platform. With Coables,
                you can access quality therapy from the comfort of your home,
                choose therapists based on qualifications and reviews, and
                schedule sessions that fit your schedule seamlessly.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            alignItems={'center'}
            my={4}
            spacing={3}
            sx={{
              px: 2,
            }}
          >
            <Grid item xs={12} sm={7} order={{ xs: 2, sm: 1 }}>
              <Typography sx={{ textAlign: 'center' }} variant="subtitle1">
                Together, The Blessed Ones and Coables are revolutionizing
                therapy access and delivery, ensuring that every child receives
                the support they need to thrive. Join us in creating a brighter
                future {'"'}From Isolation To Inclusion.{'"'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} order={{ xs: 1, sm: 2 }}>
              <Stack
                sx={{
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
              >
                <img
                  alt="carousel"
                  height={'auto'}
                  width={'100%'}
                  style={{ objectFit: 'cover' }}
                  src={Inclusion}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            item
            alignItems={'center'}
            my={4}
            spacing={3}
            sx={{
              px: 2,
            }}
          >
            <Grid item xs={12} sm={5}>
              <Stack
                sx={{
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
              >
                <img
                  alt="carousel"
                  height={'auto'}
                  width={'100%'}
                  style={{ objectFit: 'cover' }}
                  src={Professionals}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography sx={{ textAlign: 'center' }} variant="subtitle1">
                At The Blessed Ones, our team of dedicated professionals,
                including doctors, therapists, and special educators, is
                committed to providing personalized therapies that promote
                holistic development and integration for children with diverse
                needs. Our range of therapies, including occupational therapy,
                behavioral therapy, speech therapy, and more, is designed to
                empower children and support their journey towards independence
                and inclusion.
              </Typography>
            </Grid>
          </Grid>

          <Card
            sx={{
              borderRadius: 4,
              backgroundColor: (theme) => theme.palette.primary.dark,
              p: 2,
              mb: 2,
              mt: 5,
              mx: 2,
            }}
          >
            <CardContent
              sx={{
                borderRadius: 4,
                py: 3,
                border: (theme) => `1px solid ${theme.palette.primary.light}`,
              }}
            >
              <Grid container justifyContent={'center'}>
                <Grid item xs={12} md={8}>
                  <Typography
                    sx={{ textAlign: 'center' }}
                    variant="h5"
                    color={'white'}
                    lineHeight={1.5}
                  >
                    Discover the difference at The Blessed Ones and Coables
                    today.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AboutUs;
