import React from 'react';

import { Box, Fade, Modal, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import ButtonWithLoading from './ButtonWithLoading';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
};
const ConfirmationModal = ({
  open,
  handleSubmit,
  handleCancel,
  title,
  isLoading,
  description,
}) => {
  return (
    <Modal onClose={handleCancel} open={open}>
      <Fade timeout={400} in={open} mountOnEnter unmountOnExit>
        <Box sx={style}>
          <Typography>{title}</Typography>
          {/* <Typography>{description}</Typography> */}
          <Stack mt={2} direction={'row'} gap={2} alignItems={'center'}>
            <ButtonWithLoading
              disabled={isLoading}
              onClick={handleCancel}
              title="Cancel"
              variant="outlined"
            />
            <ButtonWithLoading
              isLoading={isLoading}
              onClick={handleSubmit}
              title="Yes"
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  description: PropTypes.any,
  handleCancel: PropTypes.any,
  handleSubmit: PropTypes.any,
  isLoading: PropTypes.any,
  open: PropTypes.any,
  title: PropTypes.any,
};

export default ConfirmationModal;
