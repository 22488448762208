import React, { useState, useEffect } from 'react';

import { Backdrop, Box, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';

const OpenInAppButton = () => {
  const [searchParams] = useSearchParams();
  const [mobileScreenConfig, setMobileScreenConfig] = useState({});
  useEffect(() => {
    const appConfig = {};
    const previousPath = JSON.parse(sessionStorage.getItem('previousPath'));

    appConfig.open = !sessionStorage.getItem('openInApp');

    if (searchParams.get('appRedirectUrl')) {
      appConfig.url = `${searchParams.get('appRedirectUrl')}`;
    } else if (previousPath?.search) {
      const searchParams = new URLSearchParams(previousPath.search);
      appConfig.url = searchParams.get('appRedirectUrl');
    } else {
      appConfig.url = 'app/SplashScreen/app';
    }
    setMobileScreenConfig(appConfig);
  }, [searchParams]);
  console.log('navigator.userAgent', navigator.userAgent);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  console.log('isMobile', isMobile);
  if (!isMobile) {
    return null;
  }

  const handleClose = () => {
    sessionStorage.setItem('openInApp', true);
    setMobileScreenConfig(null);
  };
  console.log('mobileScreenConfig', mobileScreenConfig);
  return (
    mobileScreenConfig?.open && (
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            p: 2,
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100,
            flexDirection: 'column',
            borderTopRightRadius: '14px',
            borderTopLeftRadius: '14px',
            boxShadow: '-1px -9px 11px 1px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="buttonText">See coables in...</Typography>
          <Stack mt={2} gap={2} width={'100%'}>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Typography>Coables App</Typography>
              <Button
                sx={{ width: '106px' }}
                variant="contained"
                color="primary"
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();

                  const userAgent =
                    navigator.userAgent || navigator.vendor || window.opera;
                  if (/android/i.test(userAgent)) {
                    window.location.href = `coables://${mobileScreenConfig.url}`;
                    setTimeout(() => {
                      window.location.href =
                        'https://play.google.com/store/apps/details?id=com.coables&pcampaignid=web_share';
                    }, 2000);
                  } else if (
                    /iPad|iPhone|iPod/.test(userAgent) &&
                    !window.MSStream
                  ) {
                    window.location.href = `coables://${mobileScreenConfig.url}`;
                    setTimeout(() => {
                      window.location.href =
                        'https://apps.apple.com/in/app/coables/id6544796262';
                    }, 2000);
                  } else {
                    alert('This feature is only available on mobile devices.');
                  }
                }}
              >
                <Typography color={'secondary'}>Open</Typography>
              </Button>
            </Stack>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Typography>Browser</Typography>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Backdrop>
    )
  );
};

export default OpenInAppButton;
