import React from 'react';

import dayjs from 'dayjs';

import Therapists from './Therapists';
import TopSection from './TopSection';

import useGetUserData from '../../../hooks/useGetUserData';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);
const Home = () => {
  const { role } = useGetUserData();
  return <TopSection>{role !== 'therapists' && <Therapists />}</TopSection>;
};

export default Home;
