import React from 'react';

import { Icon } from '@iconify/react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

/**
 * Common component for text input
 */

export const HookTextField = ({ required, label, err, ...props }) => {
  const { field, fieldState } = useController(props);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <TextField
      {...props}
      label={label && (!required ? label : label + ' *')}
      type={
        props.type === 'password'
          ? showPassword
            ? 'text'
            : 'password'
          : props.type
      }
      sx={{
        ...props?.sx,
        // change label and border color when readonly
        '& .MuiInputLabel-outlined': {
          color: (theme) => theme.palette.text.primary,
        },
        '&:has([readonly])': {
          '& .MuiInputLabel-outlined': {
            color: (theme) => theme.palette.text.primary,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.primary.main,
          },
        },
      }}
      InputProps={
        props?.InputProps
          ? props.InputProps
          : {
              endAdornment:
                props.type === 'password' ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Icon icon="mdi-light:eye-off" />
                      ) : (
                        <Icon icon="iconamoon:eye-light" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }
      }
      fullWidth
      {...field}
      error={
        // (fieldState?.isTouched || formState?.isSubmitted) &&
        Boolean(fieldState?.error) || Boolean(err)
      }
      helperText={
        // (fieldState?.isTouched || formState?.isSubmitted) &&
        fieldState?.error?.message || err
      }
    />
  );
};

HookTextField.propTypes = {
  InputProps: PropTypes.any,
  err: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.any,
  type: PropTypes.string,
  sx: PropTypes.any,
};
