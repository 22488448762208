import React from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import ModalContainer from '../../../components/common/ModalContainer';

const InquiryInfoModal = ({ handleCancel, open, inquiryData }) => {
  return (
    <>
      <ModalContainer
        handleCancel={handleCancel}
        open={open}
        maxWidth={'sm'}
        fullWidth={true}
        title={
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <Typography variant="h5">Inquiry Information</Typography>
          </Stack>
        }
      >
        <Divider />
        <Stack>
          <Stack mt={2} direction={'row'} gap={3}>
            <Stack width={'100%'} gap={2}>
              <Stack direction={'row'} gap={1}>
                <Typography flex={0.5} variant="subtitle2">
                  Name
                </Typography>
                <Stack flex={1} direction={'row'} gap={1}>
                  <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                    {inquiryData?.name}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={'row'} gap={1}>
                <Typography flex={0.5} variant="subtitle2">
                  Email
                </Typography>
                <Stack flex={1} direction={'row'} gap={1}>
                  <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                    {inquiryData?.email}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={'row'} gap={1}>
                <Typography flex={0.5} variant="subtitle2">
                  Date
                </Typography>
                <Stack flex={1} direction={'row'} gap={1}>
                  <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                    {dayjs(inquiryData?.createdAt).format(
                      'ddd, DD MMM YYYY, hh:mm A'
                    )}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={'row'} gap={1}>
                <Typography flex={0.5} variant="subtitle2">
                  Message
                </Typography>
                <Stack flex={1} direction={'row'} gap={1}>
                  <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                    {inquiryData?.message}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </ModalContainer>
    </>
  );
};

export default InquiryInfoModal;

InquiryInfoModal.propTypes = {
  handleCancel: PropTypes.func,
  open: PropTypes.any,
  inquiryData: PropTypes.any,
};
