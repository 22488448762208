/* eslint-disable quotes */
import React from 'react';

import { Icon } from '@iconify/react';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { LIGHT } from '../../store/theme/colors';

const permissionList = [
  'Camera and Bluetooth Access: Required for video conferencing to allow real-time interaction between therapists and children.',
  'Send and Receive SMS/Emails: Essential for sending session reminders, receiving confirmations, sharing detailed session notes and updates, and detecting OTP (One-Time Password) messages for user authentication.',
];

const emergencySituations = [
  'In case of any medical emergency, contact your healthcare provider or emergency services immediately.',
];
const claimedBenefits = [
  "Enhances children's developmental progress through tailored therapy sessions.",
  'Provides comprehensive support and detailed session notes to track progress.',
  'Facilitates effective therapy treatments, including speech therapy, occupational therapy, behavioral therapy, play therapy, and music and dance therapy.',
];

const requiredDisclaimers = [
  'Professional Advice: Always seek the advice of a doctor or qualified healthcare professional with any questions regarding a medical condition.',
  'Not a Substitute for Professional Care: The app is not a substitute for in-person professional medical or therapeutic advice, diagnosis, or treatment.',
  "Supervision Required: Parents should supervise their children's use of the app and therapy sessions.",
  'Data Privacy: Ensure that all personal and medical data shared through the app is kept secure and private.',
];

const intendedUsers = [
  'Children: Primarily designed for children with special needs.',
  'Parents: For parents looking for convenient, expert care for their children.',
  'Therapists: Professional therapists providing specialized care.',
];
const risksAssociated = [
  'Miscommunication: Possible miscommunication between therapists and parents if not clarified properly.',
  'Inappropriate Use: Risk of using the app without proper supervision.',
];
const commissionExample = [
  'Total Session Fee: ₹100',
  'Coables Commission (15%): ₹15',
  'Amount Transferred to Therapist: ₹100 - ₹15 = ₹85',
];
const termsConditions = [
  {
    title: 'Acceptance of Terms',
    paragraph: [
      'By accessing or using this website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use this website.',
    ],
  },
  {
    title: 'Use License',
    paragraph: [
      'Permission is granted to temporarily download one copy of the materials (information or software) on the website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title.',
    ],
  },
  {
    title: 'User Conduct',
    paragraph: [
      'When using this website, you agree not to engage in any of the following prohibited activities:',
      'Violating laws or regulations Infringing upon intellectual property rights Posting or transmitting any unlawful, threatening, defamatory, obscene, or otherwise objectionable content Transmitting viruses or any other malicious code',
    ],
  },
  {
    title: 'Intellectual Property Rights',
    paragraph: [
      'The content and materials on this website, including but not limited to text, images, logos, and trademarks, are the property of Coables and are protected by copyright and other intellectual property laws.',
    ],
  },
  {
    title: 'Privacy Policy',
    paragraph: [
      'Your use of this website is also subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using this website, you consent to the collection and use of your information as described in the Privacy Policy.',
    ],
  },
  {
    title: 'Confidential Information',
    paragraph: [
      'Mean all information disclosed by a party ("Disclosing Party") to the other party ("Receiving Party"), whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. Coables Confidential Information shall include the terms of this Agreement and all Invoices (including all non-public pricing information). Confidential Information of each party shall include (without limitation) the business and marketing plans, technology and technical information, product plans and designs, and business processes disclosed by such party.',
      "However, Confidential Information shall not include any information that (i) is or becomes generally known to the public without breach of obligation owed to the Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation to the Disclosing Party, (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party without the use of Disclosing Party's Confidential Information.",
    ],
  },
  {
    title: 'Limitations of Liability',
    paragraph: [
      'In no event shall Coables be liable for any damages arising out of the use or inability to use the materials on the website, even if Coables or an authorized representative been notified orally or in writing of the possibility of such damage.',
    ],
  },
  {
    title: 'Governing Law',
    paragraph: [
      'These terms and conditions are governed by and construed in accordance with the laws of India and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.',
    ],
  },
  {
    title: 'Changes to Terms',
    paragraph: [
      'Coables reserves the right to revise these terms and conditions at any time without notice. By using this website, you agree to be bound by the current version of these terms and conditions.',
    ],
  },
  {
    title: 'Contact Information',
    paragraph: [
      'If you have any questions or concerns about these terms and conditions, please contact us at support@coables.com.',
    ],
  },
];

const TermsConditions = ({ onboard = false, isModal = false, hideModal }) => {
  return (
    <Stack
      sx={{
        flex: 1,
        height: '100%',
      }}
    >
      <Grid container justifyContent={'center'} sx={{ px: isModal ? 0 : 2 }}>
        <Grid
          item
          xl={onboard || isModal ? 12 : 6}
          md={onboard || isModal ? 12 : 8}
          sx={{
            boxShadow: '0px 4px 30px 0px #0000000D',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          {
            <Stack
              sx={{
                py: onboard ? 3 : 4,
                bgcolor: (theme) =>
                  onboard ? 'transparent' : theme.palette.primary.light,
                mb: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                variant={'h4'}
                sx={{
                  textAlign: onboard ? 'left' : 'center',
                  px: 4,
                  width: '100%',
                }}
              >
                Terms & Conditions
              </Typography>

              {isModal ? (
                <IconButton
                  sx={{
                    border: (theme) =>
                      `1px solid ${theme.palette.text.secondary}`,
                    backgroundColor: 'transparent',
                    marginRight: 2,
                  }}
                  onClick={() => hideModal?.()}
                >
                  <Icon icon={'gridicons:cross'} />
                </IconButton>
              ) : null}
            </Stack>
          }
          <Stack sx={{ px: 4, mb: 2 }}>
            <Stack gap={4} sx={{ textAlign: 'justify' }}>
              {termsConditions.map((term, index) => {
                return (
                  <Stack key={index} gap={1}>
                    <Typography variant="h6">
                      {index + 1}. {term.title}
                    </Typography>
                    <Stack gap={2}>
                      {term.paragraph.map((para, index) => {
                        return (
                          <Typography variant="body1" key={index}>
                            {para}
                          </Typography>
                        );
                      })}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
            <Stack gap={1} sx={{ mt: 3 }}>
              <Stack gap={1}>
                <Typography variant="h6">
                  {termsConditions?.length + 1}. App’s Purpose
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant="body1">
                  Coables is designed to provide online therapy sessions for
                  children with special needs, such as Autism, ADHD, Down
                  syndrome, cerebral palsy, GDD, and learning disabilities. The
                  app connects families with highly qualified therapists,
                  allowing for convenient, personalized care from the comfort of
                  their home.
                </Typography>
                <Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 'bold',
                    }}
                  >
                    Claimed Benefits:
                  </Typography>
                  <List dense>
                    {claimedBenefits.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: '30px' }}>
                            <Icon color="#07404B" icon={'oui:dot'} />
                          </ListItemIcon>
                          <Typography>{item}</Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
                <Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 'bold',
                    }}
                  >
                    Intended Users:
                  </Typography>
                  <List dense>
                    {intendedUsers.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: '30px' }}>
                            <Icon color="#07404B" icon={'oui:dot'} />
                          </ListItemIcon>
                          <Typography>{item}</Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
                <Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 'bold',
                    }}
                  >
                    Risks Associated with the Use of the App:
                  </Typography>
                  <List dense>
                    {risksAssociated.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: '30px' }}>
                            <Icon color="#07404B" icon={'oui:dot'} />
                          </ListItemIcon>
                          <Typography>{item}</Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
                <Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 'bold',
                    }}
                  >
                    Required Disclaimers and Warnings:
                  </Typography>
                  <List dense>
                    {requiredDisclaimers.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: '30px' }}>
                            <Icon color="#07404B" icon={'oui:dot'} />
                          </ListItemIcon>
                          <Typography>{item}</Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
                <Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 'bold',
                    }}
                  >
                    Emergency Situations:
                  </Typography>
                  <List dense>
                    {emergencySituations.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: '30px' }}>
                            <Icon color="#07404B" icon={'oui:dot'} />
                          </ListItemIcon>
                          <Typography>{item}</Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
              </Stack>
              <Stack gap={1} sx={{ mt: 1 }}>
                <Typography variant="h6">
                  {termsConditions?.length + 2}. GST Information
                </Typography>

                <Typography variant="body1">
                  All services provided by Coables are subject to the Goods and
                  Services Tax (GST) as per the prevailing rates in India. The
                  GST amount will be added to the total session fee paid by the
                  parents and will be reflected in the invoices issued by
                  Coables.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    fontWeight: 'bold',
                    marginTop: 1,
                  }}
                >
                  Example:
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '30px' }}>
                      <Icon color="#07404B" icon={'oui:dot'} />
                    </ListItemIcon>
                    <Typography>
                      If a session fee is ₹100 and the applicable GST rate is
                      18%, the total amount billed to the parent will be ₹118
                      (₹100 + ₹18 GST).
                    </Typography>
                  </ListItem>
                </List>
              </Stack>
              <Stack gap={1} sx={{ mt: 1 }}>
                <Typography variant="h6">
                  {termsConditions?.length + 3}. Commission Policy
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant="body1">
                  As part of our therapist onboarding policy, Coables charges a
                  commission of 15% per session. This commission is deducted
                  from the session charge set by the therapist. The remaining
                  amount is transferred to the therapist after the commission
                  deduction.
                </Typography>
                <Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 'bold',
                    }}
                  >
                    Example:
                  </Typography>
                  <List dense>
                    {commissionExample.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: '30px' }}>
                            <Icon color="#07404B" icon={'oui:dot'} />
                          </ListItemIcon>
                          <Typography>{item}</Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Typography variant="body1">
                    Therefore, for a session priced at ₹100, the therapist will
                    receive ₹85 after the 15% commission is deducted.
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {termsConditions?.length + 4}. Access permission required
                </Typography>
                <Typography variant="h6"></Typography>
                <Stack gap={2}>
                  <List dense>
                    {permissionList.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: '30px' }}>
                            <Icon color="#07404B" icon={'oui:dot'} />
                          </ListItemIcon>
                          <Typography>{item}</Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
                <Typography variant="body1">
                  We prioritize your privacy and ensure all data is handled
                  securely. Thank you for your understanding and support. For
                  questions, contact us at{' '}
                  <a
                    style={{
                      color: LIGHT.primary.darker,
                      textDecoration: 'underline',
                    }}
                    target="_blank"
                    href="mailto:info@coables.com"
                    rel="noreferrer"
                  >
                    info@coables.com
                  </a>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TermsConditions;

TermsConditions.propTypes = {
  onboard: PropTypes.bool,
  isModal: PropTypes.bool,
  hideModal: PropTypes.func,
};
