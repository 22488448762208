import React from 'react';

import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import TableWrapper from '../../../../components/common/TableWrapper';
import { DisplayDocuments } from '../../../../components/common/UploadDocuments';
import UploadImage from '../../../../components/common/UploadImage';
import { useGetParentQuery } from '../../../../store/apis/user';
import { getImage } from '../../../../utils/commonFunctions';

const ParentInfo = () => {
  const { id } = useParams();
  const {
    data: parentData,
    isLoading,
    isFetching,
  } = useGetParentQuery({ id: id }, { skip: !id });
  const imageUrl = getImage({ id, role: 'parents' });

  const generateAddressString = (address) => {
    if (address?.city) {
      let addressString = address?.street1 || '';
      if (address?.street2) {
        addressString += `, ${address?.street2 || ''}`;
      }
      addressString += `, ${address?.city || ''}, ${address?.state || ''} - ${address?.postalCode || ''}, ${address?.country || ''}`;
      console.log('addressString', addressString, addressString.length);
      return addressString;
    }
    return '-';
  };

  const columns = [
    {
      id: 'name',
      label: 'Name',
      format: (row) => row.name,
    },
    {
      id: 'age',
      label: 'Age',
      format: (row) => <Typography variant="subtitle2">{row.age}</Typography>,
    },
    {
      id: 'previousDiagnosis',
      label: 'Previous Diagnosis',
      format: (row) => (
        <>
          <Typography variant="subtitle2">
            {row.previousDiagnosis.map((prevD) => prevD.name).join(', ')}
          </Typography>
        </>
      ),
    },
    {
      id: 'lookingFor',
      label: 'Looking for',
      format: (row) => (
        <>
          <Typography variant="subtitle2">
            {row.lookingFor.map((lookingF) => lookingF.name).join(', ')}
          </Typography>
        </>
      ),
    },
    {
      id: 'collapseContent',
      label: 'collapseContent',
      format: (row) => (
        <>
          {row.previousAssessment?.length ? (
            <DisplayDocuments documents={row.previousAssessment} />
          ) : (
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                wordBreak: 'break-all',
              }}
              variant="subtitle2"
            >
              {'No documents available!'}
            </Typography>
          )}
        </>
      ),
    },
  ];
  console.log('parentData', parentData);
  return (
    <Stack>
      {(isFetching || isLoading) && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          my={20}
        >
          <CircularProgress size={30} />
        </Box>
      )}
      {parentData && (
        <Stack gap={3}>
          <Stack direction={{ xs: 'column', md: 'row' }} gap={3}>
            <Stack alignItems={'center'}>
              <UploadImage editMode={false} imageUrl={imageUrl} />
            </Stack>
            <Grid sx={{ width: '100%' }} spacing={2} container>
              <Grid item xs={12} md={6} lg={4}>
                <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                  Name
                </Typography>
                <Typography variant="subtitle2">
                  {parentData?.name || '-'}
                </Typography>
              </Grid>{' '}
              <Grid item xs={12} md={6} lg={4}>
                <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                  Email
                </Typography>
                <Typography
                  sx={{ wordBreak: 'break-word' }}
                  variant="subtitle2"
                >
                  {parentData?.email || '-'}
                </Typography>
              </Grid>{' '}
              <Grid item xs={12} md={6} lg={4}>
                <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                  Phone
                </Typography>
                <Typography variant="subtitle2">
                  {parentData?.phone || '-'}
                </Typography>
              </Grid>{' '}
              <Grid item xs={12} md={6} lg={4}>
                <Typography fontWeight={'fontWeightBold'} variant="subtitle2">
                  Address
                </Typography>
                <Typography variant="subtitle2">
                  {generateAddressString(parentData?.location)}
                </Typography>
              </Grid>{' '}
            </Grid>
          </Stack>
          {parentData?.kids?.length ? (
            <Stack gap={2}>
              <Typography color={'secondary'} align="left" variant="h6">
                Children details
              </Typography>
              <TableWrapper
                rowKey={'_id'}
                rows={parentData.kids}
                columns={columns}
              />
            </Stack>
          ) : null}
        </Stack>
      )}
    </Stack>
  );
};

export default ParentInfo;
